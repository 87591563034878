import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from 'axios';
import { RiPencilLine, RiDeleteBinLine, RiErrorWarningLine, RiCalendar2Fill, RiHistoryFill, RiEyeLine, RiFileTextLine, RiSearchLine, RiGroupLine, RiUserLine } from 'react-icons/ri';
import Pagination from "../Tools/Pagination";
import { Modal } from "../Modal/Modal";
import { BsInstagram, BsYoutube, BsTiktok, BsFacebook, BsThreeDotsVertical, BsTwitterX, BsThreads } from 'react-icons/bs';
import { isMobile as checkIsMobile, handleResize, debounce } from '../../utils/utils';
import { getFileTypeFromFirebasePath } from "../../utils/getFileType";
import Tabs from "../Navigation/MenuTabs/Tabs";
import DropdownButton from "../Tools/DropdownButton";
import { motion } from 'framer-motion';
import { useWorkspace } from '../../context/WorkspaceContext';
import { formatCustomDate, formatCustomDateTime } from "../../utils/date";
import { Button } from "../../ui/button";
import ContentLoader from 'react-content-loader';
import ManageUpload from "../ManageUpload/ManageUpload";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const DraftsBig = ({ onEditDraft, itemsPerPage, showFilter = false }) => {
    const [drafts, setDrafts] = useState([]);
    const [activeMainTab, setActiveMainTab] = useState('draft');
    const [activeFilterTab, setActiveFilterTab] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [tabs, setTabs] = useState([
        { id: 'draft', label: 'Drafts', count: { length: 0, color: 'bg-blue-500' } },
        { id: 'scheduled', label: 'Scheduled', count: { length: 0, color: 'bg-yellow-500' } },
        { id: 'uploaded', label: 'History', count: { length: 0, color: 'bg-green-500' } },
        { id: 'feeds', label: 'Feeds', count: { length: 0, color: 'bg-purple-500' } },
    ]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [modalGroups, setModalGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const hasRunOnceRef = useRef(false);
    const [isMobile, setIsMobile] = useState(checkIsMobile());
    const { activeWorkspace, idToken } = useWorkspace();
    const filterTabs = [
        { id: 'all', label: 'All', icon: '' },
        { id: 'instagram', label: '', icon: <BsInstagram size={27} color='#cd486b' /> },
        { id: 'facebook', label: '', icon: <BsFacebook size={27} color='blue' /> },
        { id: 'threads', label: '', icon: <BsThreads size={27} color='black' /> },
        { id: 'tiktok', label: '', icon: <BsTiktok size={27} color='black' /> },
        { id: 'youtube', label: '', icon: <BsYoutube size={35} color='red' /> },
        { id: 'x', label: '', icon: <BsTwitterX size={27} color='black' /> }
    ];
    const [isViewUploadModalOpen, setIsViewUploadModalOpen] = useState(false);
    const [selectedDrafts, setSelectedDrafts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showGrouped, setShowGrouped] = useState(false);

    useEffect(() => {
        const debouncedHandleResize = debounce(() => handleResize(setIsMobile), 100);
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, [isMobile]);

    // First define updateTabCount with useCallback
    const updateTabCount = useCallback(async (mergedData) => {
        if (mergedData.length <= 0) return;

        const tabCounts = {
            draft: 0,
            scheduled: 0,
            failed: 0,
            uploaded: 0,
            feeds: 0,  // Add feeds count
        };
        
        mergedData.forEach(group => {
            if (group.status === 'draft') {
                tabCounts.draft++;
            } else if (group.status === 'failed') {
                tabCounts.failed++;
            }
            else if (group.status === 'scheduled') {
                tabCounts.scheduled++;
            }
            else if (group.status === 'uploaded') {
                tabCounts.uploaded++;
                tabCounts.feeds++;
                } 
        });

        const hasFailedDrafts = tabCounts.failed > 0;

        setTabs(prevTabs => {
            let updatedTabs = [...prevTabs];

            if (hasFailedDrafts && !updatedTabs.find(tab => tab.id === 'failed')) {
                updatedTabs.unshift({ id: 'failed', label: 'Failed', count: { length: 0, color: 'bg-red-500' } });
                setActiveMainTab('failed');
            }

            return updatedTabs.map(tab => {
                if (tab.id in tabCounts) {
                    return { ...tab, count: { ...tab.count, length: tabCounts[tab.id] } };
                }
                return tab;
            });
        });

        if (!hasFailedDrafts && activeMainTab === 'failed') {
            setActiveMainTab('draft');
        }
    }, [activeMainTab]);

    // Now define getDrafts which uses updateTabCount
    const getDrafts = useCallback(async () => {
        if (!activeWorkspace || !idToken) return;

        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/drafts', {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` },
            });
            const arr = response.data;
            console.log(arr);
            setDrafts(arr);
            await updateTabCount(arr);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, [activeWorkspace, idToken, updateTabCount]);

    useEffect(() => {
        if (!isDeleteModalOpen && hasRunOnceRef.current === false && idToken && activeWorkspace) {
            hasRunOnceRef.current = true;
            getDrafts();
        }

        if (isDeleteModalOpen === true) {
            hasRunOnceRef.current = false;
        }
    }, [isDeleteModalOpen, activeWorkspace, idToken, getDrafts]);

    const handleMainTabChange = (newTab) => {
        setActiveMainTab(newTab);
        setCurrentPage(1);
        setSelectedDrafts([]);
        setCurrentItems([]);
        setSearchQuery('');
        
        if (newTab !== activeMainTab) {
            setActiveFilterTab('all');
        }
        
        if (activeMainTab === 'uploaded' && newTab !== 'uploaded') {
            setShowGrouped(false);
        }
    };

    const handleFilterTabChange = (newTab) => {
        setSelectedDrafts([]);
        setActiveFilterTab(newTab);
        setCurrentPage(1);
    };

    const paginate = useCallback(() => {
        if (!drafts || drafts.length <= 0) {
            setCurrentItems([]);
            setMaxPage(1);
            return { currentItems: [], maxPage: 1 };
        }
        
        const perPage = itemsPerPage;
        const indexOfLastItem = currentPage * perPage;
        const indexOfFirstItem = indexOfLastItem - perPage;

        // For Feeds tab, show feed items
        if (activeMainTab === 'feeds') {
            // Extract all uploaded posts from each group for the feeds view
            let allFeedPosts = [];
            drafts.forEach(group => {
                if (!group || !group.posts || !Array.isArray(group.posts)) return;
                
                const feedPosts = group.posts.filter(post => 
                    post && post.status === 'uploaded' && 
                    (activeFilterTab === 'all' || post.uploadTo === activeFilterTab) &&
                    (searchQuery === '' || 
                        (post.title && post.title.toLowerCase().includes(searchQuery.toLowerCase())) || 
                        (post.description && post.description.toLowerCase().includes(searchQuery.toLowerCase())))
                );
                
                feedPosts.forEach(post => {
                    if (post && post._id) {
                        allFeedPosts.push({
                            ...post,
                            post_id: post._id,
                            originalGroupId: group.post_id
                        });
                    }
                });
            });
            
            // Sort feed posts by updatedAt timestamp (newest first)
            allFeedPosts.sort((a, b) => {
                const aTime = a.updatedAt && a.updatedAt._seconds ? new Date(a.updatedAt._seconds * 1000) : new Date(0);
                const bTime = b.updatedAt && b.updatedAt._seconds ? new Date(b.updatedAt._seconds * 1000) : new Date(0);
                return bTime - aTime;
            });
            
            const maxPage = Math.ceil(allFeedPosts.length / perPage);
            const currentItems = allFeedPosts.slice(indexOfFirstItem, indexOfLastItem);
            
            setCurrentItems(currentItems);
            setMaxPage(maxPage);
            return { currentItems, maxPage };
        } else if (activeMainTab === 'uploaded') {
            // For History tab, show individual posts or grouped posts based on showGrouped setting
            if (showGrouped) {
                // Show grouped posts (similar to other tabs)
                let sortedDrafts = drafts
                    .map(group => {
                        // Ensure group has posts
                        if (!group || !group.posts || !Array.isArray(group.posts)) {
                            return null;
                        }
                        
                        // First filter posts to only include uploaded ones and match any filter criteria
                        const uploadedPosts = group.posts.filter(post => 
                            post && post.status === 'uploaded' && 
                            (activeFilterTab === 'all' || post.uploadTo === activeFilterTab) &&
                            (searchQuery === '' || 
                                (post.title && post.title.toLowerCase().includes(searchQuery.toLowerCase())) || 
                                (post.description && post.description.toLowerCase().includes(searchQuery.toLowerCase())))
                        );
                        
                        if (uploadedPosts.length === 0) return null; // Skip if no posts match criteria
                        
                        const latestPost = uploadedPosts.reduce((latest, post) => {
                            if (!latest.updatedAt || !latest.updatedAt._seconds) return post;
                            if (!post.updatedAt || !post.updatedAt._seconds) return latest;
                            
                            const latestUpdatedAt = new Date(latest.updatedAt._seconds * 1000);
                            const postUpdatedAt = new Date(post.updatedAt._seconds * 1000);
                            return postUpdatedAt > latestUpdatedAt ? post : latest;
                        }, uploadedPosts[0]);
                        
                        const latestUpdatedAt = latestPost && latestPost.updatedAt && latestPost.updatedAt._seconds
                            ? new Date(latestPost.updatedAt._seconds * 1000)
                            : new Date(0);
                            
                        return { 
                            ...group,
                            posts: uploadedPosts, // Only include uploaded posts
                            latestUpdatedAt
                        };
                    })
                    .filter(Boolean) // Remove null entries (groups with no matching posts)
                    .sort((a, b) => b.latestUpdatedAt - a.latestUpdatedAt);
                
                const maxPage = Math.ceil(sortedDrafts.length / perPage);
                const currentItems = sortedDrafts.slice(indexOfFirstItem, indexOfLastItem);
                
                setCurrentItems(currentItems);
                setMaxPage(maxPage);
                return { currentItems, maxPage };
            } else {
                // Extract all uploaded posts from each group
                let allUploadedPosts = [];
                drafts.forEach(group => {
                    if (!group || !group.posts || !Array.isArray(group.posts)) return;
                    
                    const uploadedPosts = group.posts.filter(post => 
                        post && post.status === 'uploaded' && 
                        (activeFilterTab === 'all' || post.uploadTo === activeFilterTab) &&
                        (searchQuery === '' || 
                            (post.title && post.title.toLowerCase().includes(searchQuery.toLowerCase())) || 
                            (post.description && post.description.toLowerCase().includes(searchQuery.toLowerCase())))
                    );
                    
                    // Add group info to each post for reference
                    uploadedPosts.forEach(post => {
                        if (post && post._id) {
                            allUploadedPosts.push({
                                ...post,
                                post_id: post._id, // Use individual post ID instead of group ID
                                originalGroupId: group.post_id // Keep reference to original group
                            });
                        }
                    });
                });
                
                // Sort uploaded posts by updatedAt timestamp (newest first)
                allUploadedPosts.sort((a, b) => {
                    const aTime = a.updatedAt && a.updatedAt._seconds ? new Date(a.updatedAt._seconds * 1000) : new Date(0);
                    const bTime = b.updatedAt && b.updatedAt._seconds ? new Date(b.updatedAt._seconds * 1000) : new Date(0);
                    return bTime - aTime;
                });
                
                const maxPage = Math.ceil(allUploadedPosts.length / perPage);
                const currentItems = allUploadedPosts.slice(indexOfFirstItem, indexOfLastItem);
                
                setCurrentItems(currentItems);
                setMaxPage(maxPage);
                return { currentItems, maxPage };
            }
        } else {
            // Original behavior for other tabs
            let sortedDrafts = drafts
                .filter(group => group && group.posts && Array.isArray(group.posts) && group.posts.length > 0)
                .map(group => {
                    const latestPost = group.posts.reduce((latest, post) => {
                        if (!latest.updatedAt || !latest.updatedAt._seconds) return post;
                        if (!post.updatedAt || !post.updatedAt._seconds) return latest;
                        
                        const latestUpdatedAt = new Date(latest.updatedAt._seconds * 1000);
                        const postUpdatedAt = new Date(post.updatedAt._seconds * 1000);
                        return postUpdatedAt > latestUpdatedAt ? post : latest;
                    }, group.posts[0]);
                    
                    const latestUpdatedAt = latestPost && latestPost.updatedAt && latestPost.updatedAt._seconds
                        ? new Date(latestPost.updatedAt._seconds * 1000)
                        : new Date(0);
                        
                    return { ...group, latestUpdatedAt };
                })
                .sort((a, b) => b.latestUpdatedAt - a.latestUpdatedAt);

            let filteredDrafts = sortedDrafts.map(group => ({
                ...group,
                posts: group.posts.filter(post =>
                    post && post.status === activeMainTab &&
                    (activeFilterTab === 'all' || post.uploadTo === activeFilterTab)
                )
            })).filter(group => group.posts.length > 0);

            const maxPage = Math.ceil(filteredDrafts.length / perPage);
            const currentItems = filteredDrafts.slice(indexOfFirstItem, indexOfLastItem);
            
            setCurrentItems(currentItems);
            setMaxPage(maxPage);
            return { currentItems, maxPage };
        }
    }, [drafts, activeMainTab, activeFilterTab, currentPage, itemsPerPage, searchQuery, showGrouped]);

    const [currentItems, setCurrentItems] = useState([]);
    const [maxPage, setMaxPage] = useState(1);
    useEffect(() => {
        paginate();
    }, [drafts, activeMainTab, activeFilterTab, currentPage, paginate]);

    const handleDeleteButtonClick = (group) => {
        // Skip if group is invalid
        if (!group) return;
        
        // For History tab (individual posts), create a mock group structure
        if (activeMainTab === 'uploaded' && !group.posts) {
            // Find the original group
            const originalGroup = drafts.find(d => d.post_id === group.originalGroupId);
            // Create a mock group with only this post
            const mockGroup = {
                post_id: group.originalGroupId,
                posts: [group]
            };
            setModalGroups(prevGroups => [...prevGroups, mockGroup]);
        } else {
            setModalGroups(prevGroups => [...prevGroups, group]);
        }
        setIsDeleteModalOpen(true);
    };

    const handleViewButtonClick = (group) => {
        // Skip if group is invalid
        if (!group) return;
        
        // For History tab (individual posts), create a mock group structure
        if (activeMainTab === 'uploaded' && !group.posts) {
            // Find the original group
            const originalGroup = drafts.find(d => d.post_id === group.originalGroupId);
            // Create a mock group with only this post
            const mockGroup = {
                post_id: group.originalGroupId,
                posts: [group]
            };
            setModalGroups(mockGroup);
        } else {
            setModalGroups(group);
        }
        setIsViewUploadModalOpen(true);
    };

    const closeModal = () => {
        setModalGroups([]);
        setIsDeleteModalOpen(false);
        setIsViewUploadModalOpen(false);
    };

    const getIconForTab = (tabId) => {
        switch (tabId) {
            case 'failed':
                return <RiErrorWarningLine className="w-4 h-4" />;
            case 'draft':
                return <RiPencilLine className="w-4 h-4" />;
            case 'scheduled':
                return <RiCalendar2Fill className="w-4 h-4" />;
            case 'uploaded':
                return <RiHistoryFill className="w-4 h-4" />;
            case 'feeds':
                return <RiEyeLine className="w-4 h-4" />;
            default:
                return null;
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= maxPage) {
            setCurrentPage(newPage);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to first page when searching
    };

    const toggleGroupView = () => {
        setShowGrouped(!showGrouped);
        setCurrentPage(1); // Reset to first page when toggling view
    };

    const toggleDraftSelection = (group) => {
        // Skip if group is invalid
        if (!group) return;
        
        setSelectedDrafts((prevSelected) => {
            // For History/Feeds tabs (individual posts), use _id as identifier
            const idToCheck = (activeMainTab === 'uploaded' || activeMainTab === 'feeds') && !group.posts ? group._id : group.post_id;
            if (!idToCheck) return prevSelected; // Skip if no valid ID
            
            const isSelected = prevSelected.some((draft) => 
                (activeMainTab === 'uploaded' || activeMainTab === 'feeds') && !draft.posts 
                    ? draft._id === idToCheck 
                    : draft.post_id === idToCheck
            );
            
            if (isSelected) {
                return prevSelected.filter((draft) => 
                    (activeMainTab === 'uploaded' || activeMainTab === 'feeds') && !draft.posts 
                        ? draft._id !== idToCheck 
                        : draft.post_id !== idToCheck
                );
            } else {
                return [...prevSelected, group];
            }
        });
    };

    const handleDeleteSelectedDrafts = () => {
        // For History or Feeds tab (individual posts), create mock groups
        if ((activeMainTab === 'uploaded' || activeMainTab === 'feeds') && selectedDrafts.length > 0 && !selectedDrafts[0].posts) {
            const mockGroups = selectedDrafts.map(post => ({
                post_id: post.originalGroupId,
                posts: [post]
            }));
            setModalGroups(mockGroups);
        } else {
            setModalGroups(selectedDrafts);
        }
        setIsDeleteModalOpen(true);
        setSelectedDrafts([]);
    };

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'draft':
                return 'bg-blue-100 text-blue-800';
            case 'scheduled':
                return 'bg-yellow-100 text-yellow-800';
            case 'failed':
                return 'bg-red-100 text-red-800';
            case 'uploaded':
                return 'bg-green-100 text-green-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    // Add a new handler for viewing a feed post in detail
    const handleFeedPostClick = (post) => {
        // Create a mock group with only this post for the ManageUpload component
        const mockGroup = {
            post_id: post.originalGroupId,
            posts: [post]
        };
        setModalGroups(mockGroup);
        setIsViewUploadModalOpen(true);
    };

    return (
        <div className="flex flex-col relative h-max">
            {/* Top Pagination */}
            <div className="w-max right-0 top-[-40px] absolute">
                {/* Multi-delete button */}
                {selectedDrafts.length > 0 && (
                    <Button variant="destructive" onClick={handleDeleteSelectedDrafts} className="text-white p-2 rounded">
                        Delete {selectedDrafts.length} Selected
                    </Button>
                )}
                {maxPage > 1 && (
                    <Pagination currentPage={currentPage} maxPage={maxPage} onPageChange={handlePageChange} />
                )}
            </div>

            {/* Main Tabs */}
            <div className={`grid w-full mb-4 ${tabs.length === 2 ? 'grid-cols-2' : tabs.length === 3 ? 'grid-cols-3' : tabs.length === 4 ? 'grid-cols-4' : 'grid-cols-2'}`}>
                {
                    tabs.map((tab) => (
                        <Button
                            variant={activeMainTab === tab.id ? 'primary' : 'outline'}
                            key={tab.id}
                            onClick={() => handleMainTabChange(tab.id)}
                        >
                            {getIconForTab(tab.id)}
                            <span className={`${activeMainTab === tab.id ? 'nav-link' : '!text-gray-500'}`}>{tab.label}</span>
                            {tab.count && (
                                <span
                                    className={`px-2 rounded-full text-xs ${tab.count.color} text-white`}
                                >
                                    {tab.count.length}
                                </span>
                            )}
                        </Button>
                    ))
                }
            </div >

            {/* Search bar and group toggle - only for History tab */}
            {(activeMainTab === 'uploaded' || activeMainTab === 'feeds') && (
                <div className="w-full flex flex-col md:flex-row gap-2 mb-4">
                    <div className="relative flex-grow">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <RiSearchLine className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                            type="text"
                            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="Search posts..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                    {activeMainTab === 'uploaded' && (
                        <Button
                            variant="outline"
                            onClick={toggleGroupView}
                            className="flex items-center gap-2 whitespace-nowrap"
                        >
                            {showGrouped ? <RiGroupLine className="h-5 w-5" /> : <RiUserLine className="h-5 w-5" />}
                            {showGrouped ? 'Group View' : 'Individual View'}
                        </Button>
                    )}
                </div>
            )}

            <div className="flex relative items-start gap-2">
                {/* Filter Tabs */}
                {showFilter && (activeMainTab === 'uploaded' || activeMainTab === 'feeds') && (
                    <div className='dashboard-section !m-0 !w-max !px-0 !py-3'>
                        <Tabs tabs={filterTabs} onTabChange={handleFilterTabChange} activeTab={activeFilterTab} main={false} useResponsiveDropdown={false} />
                    </div>
                )}
                {/* Display Draft Cards */}
                {activeMainTab && (
                    <div className={`grid gap-4 w-full ${activeMainTab === 'feeds' ? 'grid-cols-1 md:grid-cols-1' : 'grid-cols-2 md:grid-cols-3 lg:grid-cols-4'}`}>
                        {isLoading ? (
                            // Display loaders
                            Array(isMobile ? 2 : 4).fill(0).map((_, index) => (
                                <Loader key={index} isMobile={isMobile} />
                            ))
                        ) : activeMainTab === 'feeds' && currentItems && currentItems.length > 0 ? (
                            // Display Feeds content
                            currentItems.map((post, index) => (
                                <motion.div
                                    className={`flex flex-col gap-2 w-full  md:w-2/4 mx-auto --theme-colors shadow rounded-lg`}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 2 }}
                                    key={post._id + index}
                                >
                                    <div>
                                    <ManageUpload upload={post} idToken={idToken} />

                                    </div>
                                </motion.div>
                            ))
                        ) : currentItems && currentItems.length > 0 ? (
                            currentItems.map((group, index) => (
                                <motion.div
                                    className={`--theme-colors shadow rounded-lg h-max ${selectedDrafts.some(draft => draft.post_id === group.post_id) ? 'border-2 border-blue-500' : ''}`}
                                    onClick={() => toggleDraftSelection(group)}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 2 }}
                                    key={group.post_id + index}
                                >
                                    {/* Header */}
                                    <div className="p-2 border-b">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center space-x-2">
                                                {activeMainTab === 'uploaded' && !showGrouped ? (
                                                    // For History tab (individual posts), show single social media icon
                                                    <IconForSocialMedia socialMedia={group.uploadTo} />
                                                ) : activeMainTab === 'uploaded' && showGrouped && group.posts && group.posts.length > 0 ? (
                                                    // For History tab (grouped posts), show all social media icons in the group
                                                    group.posts && group.posts.length > 0 ? 
                                                        // Create a unique array of social platforms from this group
                                                        [...new Set(group.posts.map(post => post.uploadTo))].map((platform, index) => (
                                                            <IconForSocialMedia key={platform + index} socialMedia={platform} />
                                                        ))
                                                    : null
                                                ) : (
                                                    // For other tabs (grouped posts), show all social media icons
                                                    group.posts && group.posts.length > 0 ? group.posts.map((groupPost, index) => (
                                                        <IconForSocialMedia key={groupPost.post_id + index} socialMedia={groupPost.uploadTo} />
                                                    )) : null
                                                )}
                                            </div>
                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(activeMainTab === 'uploaded' ? group.status : (group.posts && group.posts.length > 0 ? group.posts[0].status : 'unknown'))}`}>
                                                {activeMainTab === 'uploaded' ? group.status.toUpperCase() : (group.posts && group.posts.length > 0 ? group.posts[0].status.toUpperCase() : 'UNKNOWN')}
                                            </span>
                                            <div className="flex space-x-2 ">
                                                <DropdownButton label={<BsThreeDotsVertical size={18} color="black" />} classes="!p-0 hover:bg-none relative" options={[
                                                    { label: `Edit`, onClick: () => onEditDraft(group), icon: <RiPencilLine size={20} className="ml-5 text-blue-600 font-bold" /> },
                                                    {
                                                        label: `Delete`,
                                                        onClick: () => handleDeleteButtonClick(group),
                                                        icon: <RiDeleteBinLine size={20} className="ml-5 text-blue-600 font-bold" />,
                                                    },
                                                    ...(activeMainTab === 'uploaded' ? [
                                                        { label: `Manage Post`, onClick: () => handleViewButtonClick(group), icon: <RiEyeLine size={20} className="ml-5 text-blue-600 font-bold" /> },
                                                    ] : []),
                                                ]} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Media */}
                                    {((activeMainTab === 'uploaded' && !showGrouped) ? group.firebase_path : 
                                      (activeMainTab === 'uploaded' && showGrouped && group.posts && group.posts.length > 0) ? group.posts[0].firebase_path :
                                      (group.posts && group.posts.length > 0) ? group.posts[0].firebase_path : 
                                      null) && (
                                        <div className="relative aspect-video bg-gray-200">
                                            <img
                                                src={
                                                    getFileTypeFromFirebasePath(
                                                        (activeMainTab === 'uploaded' && !showGrouped) ? group.firebase_path : 
                                                        (activeMainTab === 'uploaded' && showGrouped && group.posts && group.posts.length > 0) ? group.posts[0].firebase_path :
                                                        (group.posts && group.posts.length > 0) ? group.posts[0].firebase_path : 
                                                        ''
                                                    ) === 'video'
                                                        ? ((activeMainTab === 'uploaded' && !showGrouped) ? group.thumbnail : 
                                                           (activeMainTab === 'uploaded' && showGrouped && group.posts && group.posts.length > 0) ? group.posts[0].thumbnail :
                                                           (group.posts && group.posts.length > 0) ? group.posts[0].thumbnail : 
                                                           null) || `${process.env.PUBLIC_URL}/no-thumbnail.png`
                                                        : ((activeMainTab === 'uploaded' && !showGrouped) ? group.firebase_path : 
                                                           (activeMainTab === 'uploaded' && showGrouped && group.posts && group.posts.length > 0) ? group.posts[0].firebase_path :
                                                           (group.posts && group.posts.length > 0) ? group.posts[0].firebase_path : 
                                                           null) || `${process.env.PUBLIC_URL}/no-thumbnail.png`
                                                }
                                                alt={
                                                    (activeMainTab === 'uploaded' && !showGrouped) ? (group.title || group.description) : 
                                                    (activeMainTab === 'uploaded' && showGrouped && group.posts && group.posts.length > 0) ? (group.posts[0].title || group.posts[0].description) :
                                                    (group.posts && group.posts.length > 0) ? (group.posts[0].title || group.posts[0].description) : 
                                                    'Post'
                                                }
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                    )}

                                    {/* Content */}
                                    <div className="p-2">
                                        <h3 className="text-sm font-medium truncate text-gray-800">
                                            {(activeMainTab === 'uploaded' && !showGrouped) ? (group.title || '') : 
                                             (activeMainTab === 'uploaded' && showGrouped && group.posts && group.posts.length > 0) ? (group.posts[0].title || '') :
                                             (group.posts && group.posts.length > 0) ? (group.posts[0].title || '') : 
                                             ''}
                                        </h3>
                                    </div>
                                    <div className="p-2">
                                        <h3 className="text-sm font-medium truncate text-gray-700">
                                            {(activeMainTab === 'uploaded' && !showGrouped) ? group.description : 
                                             (activeMainTab === 'uploaded' && showGrouped && group.posts && group.posts.length > 0) ? group.posts[0].description :
                                             (group.posts && group.posts.length > 0) ? group.posts[0].description : 
                                             ''}
                                        </h3>
                                    </div>

                                    {/* Footer */}
                                    <div className="p-2 pt-0">
                                        <p className="text-xs text-gray-500">
                                            {activeMainTab === 'uploaded' ? (
                                                showGrouped ? (
                                                    // For grouped History view, show date of most recent post in the group
                                                    group.posts && group.posts.length > 0 && group.posts[0].updatedAt && group.posts[0].updatedAt._seconds
                                                        ? formatCustomDate(group.posts[0].updatedAt._seconds * 1000)
                                                        : 'Unknown date'
                                                ) : (
                                                    // For individual History view
                                                    group.schedule 
                                                        ? formatCustomDateTime(new Date(group.schedule))
                                                        : group.updatedAt && group.updatedAt._seconds
                                                            ? formatCustomDate(group.updatedAt._seconds * 1000)
                                                            : 'Unknown date'
                                                )
                                            ) : (
                                                // For Drafts and Scheduled tabs
                                                group.posts && group.posts.length > 0 && group.posts[0].status === 'scheduled'
                                                    ? formatCustomDateTime(new Date(group.posts[0].schedule))
                                                    : group.posts && group.posts.length > 0 && group.posts[0].updatedAt && group.posts[0].updatedAt._seconds
                                                        ? formatCustomDate(group.posts[0].updatedAt._seconds * 1000)
                                                        : 'Unknown date'
                                            )}
                                        </p>
                                    </div>
                                </motion.div>
                            ))
                        ) : (
                            <div className="flex justify-center items-center col-span-full h-64">
                                <div className="flex flex-col items-center justify-center space-y-2">
                                    <div className="p-4 rounded-full bg-gray-100">
                                        <RiFileTextLine className="md:h-12 md:w-12 h-8 w-8 text-gray-500" />
                                    </div>
                                    <h3 className="text-lg font-medium text-gray-900">No {activeMainTab} posts yet</h3>
                                    <p className="text-sm text-gray-500">Create your first draft to get started</p>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {/* Bottom Pagination */}
            {maxPage > 1 && (
                <div className="self-end">
                    <Pagination currentPage={currentPage} maxPage={maxPage} onPageChange={handlePageChange} />
                </div>
            )}

            {/* Delete Modal */}
            {isDeleteModalOpen && (
                <Modal open={isDeleteModalOpen} closeModal={closeModal} type={'VerifyDelete'} otherInfo={modalGroups} />
            )}

            {/* View Upload Modal */}
            {isViewUploadModalOpen && (
                <Modal open={isViewUploadModalOpen} closeModal={closeModal} type={'ViewUpload'} otherInfo={modalGroups} />
            )}
        </div>
    );
};

const Loader = React.memo(({ isMobile }) => (
    <div className="shadow-sm rounded-lg overflow-hidden">
        <ContentLoader
            speed={2}
            width="100%"
            height={isMobile ? 180 : 240}
            viewBox={isMobile ? "0 0 300 180" : "0 0 300 240"}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            {isMobile ? (
                <>
                    {/* Mobile card loader */}
                    {/* Header with social icons and status badge */}
                    <rect x="10" y="10" rx="4" ry="4" width="20" height="20" />
                    <rect x="40" y="10" rx="10" ry="10" width="60" height="20" />
                    <rect x="260" y="10" rx="4" ry="4" width="20" height="20" />
                    
                    {/* Media placeholder */}
                    <rect x="0" y="40" rx="0" ry="0" width="300" height="90" />
                    
                    {/* Title and description */}
                    <rect x="10" y="140" rx="3" ry="3" width="200" height="12" />
                    <rect x="10" y="160" rx="3" ry="3" width="140" height="10" />
                </>
            ) : (
                <>
                    {/* Desktop card loader */}
                    {/* Header with social icons and status badge */}
                    <rect x="10" y="10" rx="4" ry="4" width="25" height="25" />
                    <rect x="45" y="10" rx="10" ry="10" width="70" height="25" />
                    <rect x="260" y="10" rx="4" ry="4" width="25" height="25" />
                    
                    {/* Media placeholder */}
                    <rect x="0" y="45" rx="0" ry="0" width="300" height="130" />
                    
                    {/* Title and description */}
                    <rect x="10" y="185" rx="3" ry="3" width="250" height="15" />
                    <rect x="10" y="210" rx="3" ry="3" width="180" height="12" />
                </>
            )}
        </ContentLoader>
    </div>
));

const IconForSocialMedia = React.memo(({ socialMedia }) => {
    let social = socialMedia;
    if (typeof socialMedia === 'object' && socialMedia.socialMedia) {
        social = socialMedia.socialMedia;
    }
    switch (social) {
        case 'youtube':
            return <BsYoutube className="h-5 w-5 text-red-600" />;
        case 'instagram':
            return <BsInstagram className="h-5 w-5 text-pink-500" />;
        case 'tiktok':
            return <BsTiktok className="h-5 w-5 text-black" />;
        case 'facebook':
            return <BsFacebook className="h-5 w-5 text-blue-600" />;
        case 'x':
            return <BsTwitterX className="h-5 w-5 text-black" />;
        case 'threads':
            return <BsThreads className="h-5 w-5 text-black" />;
        default:
            return null;
    }
});

export default DraftsBig;