import React from 'react';
import { Button } from '../ui/button';

/**
 * Component to display error messages with a refresh button
 * @param {string} errorMessage - The error message to display
 * @param {function} onRefresh - Function to call when refresh button is clicked
 * @param {string} className - Additional CSS classes to apply
 */
const ErrorWithRefresh = ({ errorMessage, onRefresh, className = '' }) => {
  return (
    <div className={`p-4 bg-red-50 border border-red-200 rounded-lg ${className}`}>
      <p className="text-center mb-3">{errorMessage || 'An error occurred while loading data.'}</p>
      <div className="flex justify-center">
        <Button
          variant="outline"
          size="lg"
          onClick={onRefresh}
          className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Refresh Data
        </Button>
      </div>
    </div>
  );
};

export default ErrorWithRefresh; 