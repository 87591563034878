import React from 'react';
import { FaEllipsisH, FaUserPlus } from 'react-icons/fa';
import { Button } from "../../ui/button";

// Dummy data for the Instagram profile
const dummyProfileData = {
  username: 'instagram_user',
  profilePicture: 'https://via.placeholder.com/150',
  posts: 86,
  followers: 1452,
  following: 732,
  fullName: 'Instagram User',
  bio: 'Digital creator | Travel enthusiast 🌍 | Food lover 🍕',
  website: 'www.frenzypost.com',

  posts: [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ]
};

const InstagramProfile = ({ workspaceData = {}, gridImages = [] }) => {
  // Override dummy data with workspace data if provided
  const profileData = {
    ...dummyProfileData,
    username: workspaceData.username || dummyProfileData.username,
    profilePicture: workspaceData.profilePicture || dummyProfileData.profilePicture,
    fullName: workspaceData.fullName || dummyProfileData.fullName,
  };

  const { 
    username, 
    profilePicture, 
    followers, 
    following, 
    fullName, 
    bio, 
    website, 
  } = profileData;

  // Use grid images from props if available, otherwise use dummy posts
  const displayPosts = gridImages.length > 0 
    ? gridImages.map((src, index) => ({ id: index, imageUrl: src }))
    : profileData.posts;

  const postCount = displayPosts.length;

  return (
    <div className="max-w-[935px] mx-auto p-5 font-sans text-[#262626]">
      {/* Header section */}
      <div className="flex flex-col md:flex-row mb-11">
        <div className="w-[150px] h-[150px] mx-auto md:mx-0 md:mr-8 mb-5 md:mb-0">
          <img 
            src={profilePicture} 
            alt={username} 
            className="w-full h-full rounded-full object-cover border border-[#dbdbdb] p-0.5"
          />
        </div>
        <div className="flex-1">
          <div className="flex flex-col md:flex-row items-center md:items-start mb-5">
            <h2 className="text-2xl font-light md:mr-5 mb-2.5 md:mb-0">{username}</h2>
            <div className="flex items-center">
              <Button variant="primary">Follow</Button>
              <Button variant="outline">Message</Button>
              <Button variant="ghost"><FaUserPlus /></Button>
              <Button variant="ghost"><FaEllipsisH /></Button>
            </div>
          </div>
          <div className="flex justify-center md:justify-start mb-5">
            <div className="mr-10 text-base">
              <span className="font-semibold">{postCount}</span> posts
            </div>
            <div className="mr-10 text-base">
              <span className="font-semibold">{followers}</span> followers
            </div>
            <div className="mr-10 text-base">
              <span className="font-semibold">{following}</span> following
            </div>
          </div>
          <div className="text-sm leading-6 text-center md:text-left">
            <h1 className="text-base font-semibold my-0 mb-1">{fullName}</h1>
            <p className="my-0 mb-1">{bio}</p>
            <a href={`https://${website}`} className="text-[#00376b] font-semibold no-underline">{website}</a>
          </div>
        </div>
      </div>

      {/* Stories highlight section */}
      <div className="flex overflow-x-auto py-2.5 mb-11 border-b border-[#dbdbdb]">
        {[1,2,3].map(story => (
          <div key={story.id} className="flex flex-col items-center mr-6">
            <div className="w-[50px] h-[50px] p-[3px] bg-gradient-to-r from-[#fcaf45] via-[#e95950] to-[#833ab4] rounded-full mb-2">
              <div 
                src={story.thumbnail} 
                alt={story.title} 
                className="w-full h-full rounded-full object-cover border-2 border-white" 
              />
            </div>
            <span className="text-xs text-[#262626]">{story.title}</span>
          </div>
        ))}
      </div>

      {/* Post grid section */}
      <div className="mt-5 w-full">
        {displayPosts.length > 0 ? (
          <div className="grid grid-cols-3 gap-3 md:gap-3 sm:gap-1">
            {displayPosts.map((post) => (
              <div key={post.id} className="relative w-full pb-[100%] overflow-hidden bg-gray-500">
                {post.imageUrl && (
                  <img 
                    src={post.imageUrl} 
                    alt={`Post ${post.id}`} 
                    className="absolute w-full h-full object-cover cursor-pointer" 
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-10 text-gray-500">
            No posts yet
          </div>
        )}
      </div>
    </div>
  );
};

export default InstagramProfile; 