import React, { useState, useEffect, useRef } from 'react'; // Import useState, useEffect, useRef
import { Link, useLocation } from 'react-router-dom';
import {
    FaHome,
    FaPlusCircle,
    FaUser,
    FaCog,
    FaCalendarAlt, // Import icon for Schedule
} from 'react-icons/fa';
import { GoTools } from 'react-icons/go';


const HorizontalMobileNavbar = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const isActive = (path) => {
        return currentPath === path ? 'text-blue-600' : 'text-gray-600';
    };

    // State to manage the visibility of the Dashboard submenu
    const [isDashboardMenuOpen, setDashboardMenuOpen] = useState(false);

    // Ref for detecting clicks outside the submenu
    const submenuRef = useRef(null);

    useEffect(() => {
        // Function to handle click outside
        const handleClickOutside = (event) => {
            if (submenuRef.current && !submenuRef.current.contains(event.target)) {
                setDashboardMenuOpen(false);
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Determine which icon and label to show
    const dashboardIcon = currentPath === '/dashboard/schedule' ? FaCalendarAlt : FaHome;
    const dashboardLabel = currentPath === '/dashboard/schedule' ? 'Schedule' : 'Dashboard';
    const dashboardActiveClass =
        isActive('/dashboard') === 'text-blue-600' || isActive('/dashboard/schedule') === 'text-blue-600'
            ? 'text-blue-600'
            : 'text-gray-600';

    return (
        <div className="sticky bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-200 z-[99]">
            <div className="flex justify-around items-center h-16">
                {/* Dashboard menu with submenu */}
                <div className="relative">
                    <div
                        onClick={() => setDashboardMenuOpen(!isDashboardMenuOpen)}
                        className={`flex flex-col items-center cursor-pointer ${dashboardActiveClass}`}
                    >
                        {React.createElement(dashboardIcon, { size: 24 })}
                        <span className="text-xs">{dashboardLabel}</span>
                    </div>
                    {/* Submenu */}
                    <div
                        ref={submenuRef}
                        className={`absolute bottom-[40px] left-[-20px] mb-2 bg-white rounded-md transition-all duration-200 ${isDashboardMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
                            }`}
                    >
                        <Link
                            to="/dashboard"
                            className={`flex items-center px-4 py-2 text-md hover:bg-gray-100 ${isActive('/dashboard') === 'text-blue-600' ? 'text-blue-600' : 'text-gray-700'
                                }`}
                            onClick={() => setDashboardMenuOpen(!isDashboardMenuOpen)} // Close submenu on click
                        >
                            <FaHome className="mr-2" />
                            Dashboard
                        </Link>
                        <Link
                            to="/dashboard/schedule"
                            className={`flex items-center px-4 py-2 text-md hover:bg-gray-100 ${isActive('/dashboard/schedule') === 'text-blue-600' ? 'text-blue-600' : 'text-gray-700'
                                }`}
                            onClick={() => setDashboardMenuOpen(!isDashboardMenuOpen)} // Close submenu on click
                        >
                            <FaCalendarAlt className="mr-2" />
                            Schedule
                        </Link>
                    </div>
                </div>

                <Link
                    to="/dashboard/tools"
                    className={`flex flex-col items-center ${isActive('/dashboard/tools')}`}
                >
                    <GoTools size={24} />
                    <span className="text-xs">Tools</span>
                </Link>

                <Link to="/dashboard/upload" className="relative">
                    <FaPlusCircle size={40} className="text-blue-600" />
                </Link>

                <Link
                    to="/dashboard/socials"
                    className={`flex flex-col items-center ${isActive('/dashboard/socials')}`}
                >
                    <FaUser size={24} />
                    <span className="text-xs">Socials</span>
                </Link>

                <Link
                    to="/dashboard/settings"
                    className={`flex flex-col items-center ${isActive('/dashboard/settings')}`}
                >
                    <FaCog size={24} />
                    <span className="text-xs">Settings</span>
                </Link>
            </div>
        </div>
    );
};

export default HorizontalMobileNavbar;
