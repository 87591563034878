import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './VerticalNavbar.css';
import { FaLink, FaMoon, FaPlus, FaSun } from 'react-icons/fa';
import { BiSolidDashboard, BiHistory, BiSolidHelpCircle } from 'react-icons/bi';
import { IoShareSocialSharp } from 'react-icons/io5';
import {
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdOutlineDriveFolderUpload,
  MdOutlineCalendarMonth,
  MdOutlinePermMedia
} from 'react-icons/md';
import { TbLogout, TbTemplate } from 'react-icons/tb';
import { motion, AnimatePresence } from 'framer-motion';
import useLocalStorage from 'use-local-storage';
import { useWorkspace } from '../../../context/WorkspaceContext';
import { LuRefreshCcw } from 'react-icons/lu';
import DropdownButton from '../../Tools/DropdownButton';
import { GoTools } from 'react-icons/go';
import { getAuth, signOut } from 'firebase/auth';
import { Button } from '../../../ui/button';

const VerticalNavbar = () => {
  const [isExpanded, setIsExpanded] = useLocalStorage('navbarIsExpanded', true);
  const [toolsOpen, setToolsOpen] = useState(false); // <-- STATE FOR TOOLS SUBMENU

  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [theme, setTheme] = useLocalStorage('theme', 'light');
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(null);
  const hasRunOnceRef = useRef(false);
  const { firestoreUser } = useWorkspace();

  useEffect(() => {
    if (!hasRunOnceRef.current && location.pathname !== '') {
      setCurrentPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    localStorage.setItem('navbarIsExpanded', JSON.stringify(isExpanded));
  }, [isExpanded]);

  const toggleNavbar = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    const storedIsExpanded = localStorage.getItem('navbarIsExpanded');
    if (storedIsExpanded === null) {
      const handleResize = () => {
        const screenWidth = window.innerWidth;
        setIsExpanded(screenWidth > 768);
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [setIsExpanded]);

  const isActive = (currentPath, path) => {
    return currentPath === path ? 'active' : '';
  };


  const sidebarVariants = {
    expanded: {
      width: 230,
      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 20,
      },
    },
    collapsed: {
      width: 80,
      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 20,
      },
    },
  };

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => navigate('/login'))
      .catch((error) => console.log(error));
  };

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <motion.div
      className={`vertical-menu shadow-lg shadow-blue-800/70 ${isExpanded ? 'min-w-[200px] max-w-[200px] p-[20px]' : 'w-[80px]'
        } bg-gray-200 max-h-screen`}
      variants={sidebarVariants}
      animate={isExpanded ? 'expanded' : 'collapsed'}
      initial={false}
    >
      {/* LOGO */}
      <div className={`relative ${isExpanded ? 'py-5' : 'pt-[20px]'} flex justify-center items-center`}>
        <Link to="/dashboard" className="flex items-center justify-center relative overflow-hidden">
          <AnimatePresence mode="wait">
            {isExpanded ? (
              <motion.img
                key="expanded-logo"
                src={`${process.env.PUBLIC_URL}/logo/logo-horizontal-white.png`}
                alt="Logo"
                className="max-h-[150px]"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
              />
            ) : (
              <motion.img
                key="collapsed-logo"
                src={`${process.env.PUBLIC_URL}/logo/favicon-nobg.png`}
                alt="Logo"
                className="max-w-[60px] max-h-[60px]"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
              />
            )}
          </AnimatePresence>
        </Link>
      </div>

      {/* TOGGLE BUTTON */}
      <motion.div
        layout
        className={`px-2 py-1 text-white absolute right-0 top-20`}
        onClick={toggleNavbar}
      >
        {isExpanded ? (
          <MdArrowBackIosNew
            size={30}
            color="white"
            className="opacity-50 hover:border-gray-300 border-2 border-transparent rounded-xl p-1 cursor-pointer"
          />
        ) : (
          <MdArrowForwardIos
            size={30}
            color="white"
            className="opacity-50 hover:border-gray-300 border-2 border-transparent rounded-xl p-1 cursor-pointer"
          />
        )}
      </motion.div>

      {/* UPLOAD BUTTON */}
      <motion.div layout className={`${isExpanded ? 'pb-10' : 'mx-auto pt-[45px]'}`}>
        <Button
          size="lg"
          className={`${isExpanded ? 'py-3 px-1' : 'p-3'
            } text-sm text-white bg-gradient-to-r hover:from-blue-600 hover:to-blue-800 w-full bg-blue-600 font-bold flex items-center justify-center transition-all duration-300`}
        >
          <Link to="/dashboard/upload" className="flex">
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="nav-link"
              >
                Upload
              </motion.span>
            )}
            <FaPlus size={20} color="white" className={`${isExpanded ? 'ml-3' : ''}`} />
          </Link>
        </Button>
      </motion.div>

      {/* OVERVIEW MENU */}
      <div className="flex flex-col justify-center">
        {isExpanded && (
          <p
            className={`text-[12px] opacity-70 font-semibold nav-link mb-1`}
          >
            OVERVIEW
          </p>
        )}
        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center mt-7'} group relative hover:bg-gray-700 p-1 mb-2 rounded-md ${isActive(
            currentPath,
            '/dashboard'
          )}`}
        >
          <Link
            to="/dashboard"
            className={`flex items-center gap-2 text-[16px] ${!isExpanded ? 'group' : ''}`}
          >
            <BiSolidDashboard
              size={`${isExpanded ? '22' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="nav-link"
              >
                Dashboard
              </motion.span>
            )}
          </Link>
        </div>

        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative hover:bg-gray-700 p-1 rounded-md ${isActive(
            currentPath,
            '/dashboard/library'
          )}`}
        >
          <Link
            to="/dashboard/library"
            className={`flex items-center gap-2 text-[16px] ${!isExpanded ? 'group' : ''}`}
          >
            <MdOutlinePermMedia
              size={`${isExpanded ? '22' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="nav-link"
              >
                Library
              </motion.span>
            )}
          </Link>
        </div>
      </div>

      {/* CONTENT MENU */}
      <div className={`flex flex-col justify-center ${isExpanded ? 'mt-10' : ''}`}>
        {isExpanded && (
          <p
            className={`text-[12px] opacity-70 font-semibold nav-link mb-1`}
          >
            CONTENT
          </p>
        )}
        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2 ${isActive(
            currentPath,
            '/dashboard/upload'
          )}`}
        >
          <Link
            to="/dashboard/upload"
            className={`flex items-center gap-2 text-[16px] ${!isExpanded ? 'group' : ''}`}
          >
            <MdOutlineDriveFolderUpload
              size={`${isExpanded ? '22' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="nav-link"
              >
                Upload
              </motion.span>
            )}
          </Link>
        </div>

        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2 ${isActive(
            currentPath,
            '/dashboard/schedule'
          )}`}
        >
          <Link
            to="/dashboard/schedule"
            className={`flex items-center gap-2 text-[16px] ${!isExpanded ? 'group' : ''}`}
          >
            <MdOutlineCalendarMonth
              size={`${isExpanded ? '22' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="nav-link"
              >
                Calendar
              </motion.span>
            )}
          </Link>
        </div>

        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2 ${isActive(
            currentPath,
            '/dashboard/posts'
          )}`}
        >
          <Link
            to="/dashboard/posts"
            className={`flex items-center gap-2 text-[16px] ${!isExpanded ? 'group' : ''}`}
          >
            <BiHistory
              size={`${isExpanded ? '22' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="nav-link"
              >
                Posts
              </motion.span>
            )}
          </Link>
        </div>
        {/* Tools Parent */}
        <div
          onClick={() => setToolsOpen(!toolsOpen)}
          className={`flex items-center ${isExpanded ? 'justify-between' : 'justify-center'
            } cursor-pointer group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2`}
        >
          <div className="flex items-center gap-2 text-[16px]">
            <GoTools
              size={`${isExpanded ? '22' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="nav-link"
              >
                Tools
              </motion.span>
            )}
          </div>
          {isExpanded && (
            <MdArrowForwardIos
              className={`h-4 w-4 text-gray-400 group-hover:text-gray-200 transform transition-transform ${toolsOpen ? 'rotate-90' : ''
                }`}
            />
          )}
        </div>

        {/* Submenu Items */}
        {toolsOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            transition={{ duration: 0.2 }}
            className="flex flex-col ml-5 gap-2"
          >
            {/* Templates */}
            <Link
              to="/dashboard/tools/templates"
              className={`flex items-center gap-2 text-[14px] hover:bg-gray-700 p-1 rounded-md ${isActive(
                currentPath,
                '/dashboard/tools/templates'
              )}`}
            >
              <TbTemplate size="20" color="white" className="navbar-icon" />
              {isExpanded && (
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="nav-link"
                >
                  Templates
                </motion.span>
              )}
            </Link>

            {/* Link In Bio */}
            <Link
              to="/dashboard/tools/link-in-bio"
              className={`flex items-center gap-2 text-[14px] hover:bg-gray-700 p-1 rounded-md ${isActive(
                currentPath,
                '/dashboard/tools/link-in-bio'
              )}`}
            >
              <FaLink size="20" color="white" className="navbar-icon" />
              {isExpanded && (
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="nav-link"
                >
                  Link In Bio
                </motion.span>
              )}
            </Link>

            {/* More Tools */}
            <Link
              to="/dashboard/tools"
              className={`flex items-center gap-1 text-[12px] hover:bg-gray-600 p-1 rounded-md opacity-70  text-center ${isActive(
                currentPath,
                '/dashboard/tools'
              )}`}
            >
              {isExpanded && (
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="text-xs nav-link"
                >
                  More Tools...
                </motion.span>
              )}
            </Link>
          </motion.div>
        )}
      </div>

      {/* CONFIG MENU */}
      <div className="flex flex-col justify-end mb-[10px] flex-1">
        <p
          className={`text-[12px] opacity-70 font-semibold nav-link mb-1 ${isExpanded ? '' : 'invisible w-0'
            }`}
        >
          CONFIG
        </p>

        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2 ${isActive(
            currentPath,
            '/dashboard/socials'
          )}`}
        >
          <Link
            to="/dashboard/socials"
            className={`flex items-center gap-2 text-[16px] ${!isExpanded ? 'group' : ''}`}
          >
            <IoShareSocialSharp
              size={`${isExpanded ? '22' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="nav-link"
              >
                Socials
              </motion.span>
            )}
          </Link>
        </div>

        <div
          className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:bg-gray-700 p-1 rounded-md mb-2`}
        >
          <Link
            to="/dashboard/support"
            className={`flex items-center gap-2 text-[16px] ${!isExpanded ? 'group' : ''}`}
          >
            <BiSolidHelpCircle
              size={`${isExpanded ? '22' : '35'}`}
              color="white"
              className="navbar-icon"
            />
            {isExpanded && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="nav-link"
              >
                Support
              </motion.span>
            )}
          </Link>
        </div>
      </div>

      {/* USER PROFILE WITH MENU */}
      <div ref={menuRef} className="border-t border-gray-500 mt-4 relative flex justify-center p-2">
        <DropdownButton
          left={100}
          label={
            <div
              className="w-full flex justify-between items-center bg-transparent border-none cursor-pointer hover:bg-gray-700 p-2 rounded-md"
            >
              <div className="flex items-center gap-2 w-full justify-between">
                {/* User avatar */}
                <div
                  className={`bg-green-100 text-green-600 flex items-center justify-center rounded-full ${!isExpanded ? 'h-10 w-10' : 'h-8 w-8 '
                    } ${isActive(currentPath, '/dashboard/settings') ? 'bg-blue-100 text-blue-600' : ''}`}
                >
                  {firestoreUser.username.charAt(0)}
                </div>
                {/* Username and Email */}
                {isExpanded && (
                  <div className="text-left">
                    <div className="nav-link">{firestoreUser.username}</div>
                    <div className="text-xs nav-link whitespace-nowrap overflow-hidden text-ellipsis max-w-[120px]">
                      {firestoreUser.email}
                    </div>
                  </div>
                )}
              </div>
              {isExpanded && (
                <MdArrowForwardIos className="h-4 w-4 text-gray-400 group-hover:text-gray-200" />
              )}
            </div>
          }
          options={[
            {
              heading: (
                <div className="flex justify-between items-center gap-2">
                  <div className="text-sm font-semibold bg-green-100 text-green-600 flex items-center justify-center rounded-md px-3 py-2">
                    {firestoreUser.username.charAt(0)}
                  </div>
                  <div className="text-xs text-gray-500 flex flex-col">
                    <div className="text-sm font-semibold">
                      {firestoreUser.username}
                    </div>
                    <div className="text-xs text-gray-500">
                      {firestoreUser.email}
                    </div>
                  </div>
                </div>
              ),
            },
            {
              label: theme === 'light' ? 'Light mode' : 'Dark mode',
              onClick: () => toggleTheme(),
              icon:
                theme === 'light' ? (
                  <FaSun className="text-blue-600 font-bold" />
                ) : (
                  <FaMoon className="text-blue-600 font-bold" />
                ),
            },
            {
              label: 'Settings',
              onClick: () => navigate('/dashboard/settings'),
              icon: <LuRefreshCcw className="text-blue-600 font-bold" />,
            },
            /*
            {
              label: 'Billing',
              onClick: () => navigate('/dashboard/billing'),
              icon: <LuRefreshCcw className="text-blue-600 font-bold" />,
            },
            */
            {
              label: 'Logout',
              onClick: () => handleSignOut(),
              color: 'red',
              icon: <TbLogout size={20} color="red" />,
            },
          ]}
        />
      </div>
    </motion.div >
  );
};

export default VerticalNavbar;
