import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaCheck, FaRocket, FaUserPlus, FaUpload, FaCalendarAlt, FaCopy, FaCog } from 'react-icons/fa';
import { Modal } from '../Modal/Modal';
import { useWorkspace } from '../../context/WorkspaceContext';
import { Button } from '../../ui/button';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Guide = ({ userSettings }) => {
    const [completed, setCompleted] = useState([
        { id: 'socials', completed: false },          // index 0
        { id: 'video', completed: false },            // index 1
        { id: 'schedule', completed: false },         // index 2
        { id: 'templates', completed: false },        // index 3
        { id: 'settings', completed: false }          // index 4
    ]);

    const onboardingSteps = [
        {
            id: 1,
            link: '/dashboard/socials',
            title: "Connect Socials",
            description: "Connect and manage all your social accounts",
            icon: <FaUserPlus className="w-10 h-10 text-blue-500" />,
        },
        {
            id: 2,
            link: '/dashboard/upload',
            title: "Upload a Video",
            description: "Start sharing your content in a click",
            icon: <FaUpload className="w-10 h-10 text-blue-500" />,
        },
        {
            id: 3,
            link: '/dashboard/schedule',
            title: "Schedule A Video",
            description: "Schedule your videos to post exactly when you want",
            icon: <FaCalendarAlt className="w-10 h-10 text-blue-500" />,
        },
        {
            id: 4,
            link: '/dashboard/templates',
            title: "Create Templates",
            description: "Create and reuse your own hashtag or caption templates",
            icon: <FaCopy className="w-10 h-10 text-blue-500" />,
        },
        {
            id: 5,
            link: '/dashboard/settings',
            title: "Configure Dashboard",
            description: "Customize your dashboard settings",
            icon: <FaCog className="w-10 h-10 text-blue-500" />,
        },
    ];

    const [completedSteps, setCompletedSteps] = useState(0);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [userHidedGuide, setUserHidedGuide] = useState(false);
    const [activeStep, setActiveStep] = useState(1);

    const { activeWorkspace, idToken } = useWorkspace();
    const hasRunOnceRef = useRef(false);

    // Define check functions with useCallback before they're used
    const checkUserOnboarding = useCallback(async () => {
        // If user completed entire onboarding flow
        if (userSettings?.onboarding?.completed) {
            setCompleted(prev => {
                const updated = [...prev];
                updated[0].completed = true;
                return updated;
            });
        }
    }, [userSettings?.onboarding?.completed]);

    const checkSocials = useCallback(async () => {
        if (!idToken) return;
        try {
            const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/social_media_tokens`, {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` },
            });
            if (response.data.length > 0) {
                setCompleted(prev => {
                    const updated = [...prev];
                    updated[0].completed = true; // index 0 => 'socials'
                    return updated;
                });
            }
        } catch (error) {
            console.error('Error fetching social media tokens:', error);
        }
    }, [idToken, activeWorkspace]);

    const checkDrafts = useCallback(async () => {
        if (!idToken) return;
        try {
            const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/drafts`, {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` },
            });
            const hasUploadedDraft = response.data.some(draft => draft.status === 'uploaded');
            const hasScheduledDraft = response.data.some(draft => draft.status === 'schedule');

            setCompleted(prev => {
                const updated = [...prev];
                if (hasUploadedDraft) updated[1].completed = true;  // index 1 => 'video'
                if (hasScheduledDraft) updated[2].completed = true; // index 2 => 'schedule'
                return updated;
            });
        } catch (error) {
            console.log(error);
        }
    }, [idToken, activeWorkspace]);

    const checkTemplates = useCallback(async () => {
        try {
            const response = await axios.get(`${BACKEND_API_URL}/api/auth/user/templates`, {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` },
            });
            if (response.data.length > 0) {
                setCompleted(prev => {
                    const updated = [...prev];
                    updated[3].completed = true; // index 3 => 'templates'
                    return updated;
                });
            }
        } catch (error) {
            console.log(error);
        }
    }, [idToken, activeWorkspace]);

    // Count how many steps are completed
    useEffect(() => {
        const count = completed.reduce((acc, step) => (step.completed ? acc + 1 : acc), 0);
        setCompletedSteps(count);

        // Find first uncompleted step to set as active
        for (let i = 0; i < completed.length; i++) {
            if (!completed[i].completed) {
                setActiveStep(i + 1);
                break;
            }
        }
    }, [completed]);

    // On mount (or when idToken changes), run checks once
    useEffect(() => {
        if (!hasRunOnceRef.current && idToken) {
            hasRunOnceRef.current = true;
            (async () => {
                try {
                    await checkUserOnboarding();
                    await checkSocials();
                    await checkDrafts();
                    await checkTemplates();
                } catch (error) {
                    console.error('Failed to check user steps:', error);
                }
            })();
        }
    }, [idToken, activeWorkspace, checkDrafts, checkSocials, checkTemplates, checkUserOnboarding]);

    // -------------- UI Helpers ---------------

    const calculateProgress = () => {
        const totalSteps = completed.length;
        return ((completedSteps / totalSteps) * 100).toFixed(0);
    };

    const closeModal = (confirm) => {
        setShowConfirmationModal(false);
        if (confirm) {
            // The user wants to hide the guide permanently
            setUserHidedGuide(true);
        }
    };

    // -------------- RENDER ---------------

    if (userHidedGuide) {
        return null; // If user has hidden the guide, render nothing
    }

    return (
        <div className="dark:bg-gray-800 rounded-lg shadow-md border border-gray-200 dark:border-gray-700 p-6 --theme-colors">
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <FaRocket className="h-5 w-5 text-blue-500 mr-2" />
                    <h2 className="text-xl font-bold text-gray-900 dark:text-white border-b-2 border-blue-500">
                        Explore FrenzyPost
                    </h2>
                </div>
                <div className="flex items-center gap-2">
                <span className="text-xs font-medium text-blue-500 border border-blue-500 rounded-full px-2 py-1">
                    Step {completedSteps}/{completed.length}
                </span>
                <Button variant="outline" onClick={() => setShowConfirmationModal(true)}>
                        Hide this guide
                    </Button>
                </div>
            </div>

            <div className="flex flex-col mb-4">
                <p className="text-sm text-gray-600 dark:text-gray-300 mb-2 sm:mb-0">
                    Complete these steps to get started<span className="inline-block ml-1">🚀</span>
                </p>

                <div className="flex flex-col w-full">
                    <div className="flex justify-between items-center mb-1">

                        <span className="text-xs font-medium text-blue-500">
                            {calculateProgress()}%
                        </span>
                    </div>
                    <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                        <div
                            style={{ width: `${calculateProgress()}%` }}
                            className="h-full bg-blue-500 rounded-full transition-all duration-500 ease-in-out"
                        ></div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                {onboardingSteps.map((step, idx) => {
                    const stepIndex = step.id - 1;
                    const isCompleted = completed[stepIndex]?.completed;
                    const isActive = activeStep === step.id;

                    return (
                        <Link
                            key={step.id}
                            to={step.link}
                            className={`
                                relative flex flex-col p-4 rounded-lg border transition-all duration-200
                                ${isCompleted ? 'bg-green-50 dark:bg-green-900/10 border-green-200 dark:border-green-800' :
                                    isActive ? 'bg-white dark:bg-gray-800 border-blue-500 shadow-md' :
                                        'bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 opacity-70'}
                                hover:border-blue-500 hover:shadow-md
                            `}
                        >
                            {isCompleted && (
                                <div className="absolute top-2 right-2">
                                    <div className="bg-green-500 text-white rounded-full p-1 w-6 h-6 flex items-center justify-center">
                                        <FaCheck size={12} />
                                    </div>
                                </div>
                            )}

                            <div className="flex flex-col items-center text-center mb-2">
                                <div className="mb-3 flex items-center justify-center bg-blue-100 dark:bg-blue-900/20 rounded-full p-4">
                                    {step.icon}
                                </div>
                                <h3 className="font-semibold text-gray-900 dark:text-white mb-1 !text-black">
                                    {step.title}
                                </h3>
                                <p className="text-xs text-gray-500 dark:text-gray-400">
                                    {step.description}
                                </p>
                            </div>

                            {isActive && !isCompleted && (
                                <Button variant="primary" className="mt-2 w-full py-1.5 px-3 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors">
                                    {step.id === 1 ? "Connect" :
                                        step.id === 2 ? "Upload" :
                                            step.id === 3 ? "Schedule" :
                                                step.id === 4 ? "Create" : "Configure"}
                                </Button >
                            )}
                        </Link>
                    );
                })}
            </div>

            {/* Confirmation Modal */}
            {showConfirmationModal && (
                <Modal open={showConfirmationModal} type="ConfirmHideGuide" closeModal={closeModal} />
            )}
        </div>
    );
};

export default Guide;
