import { useEffect, useState, useRef, memo } from 'react';
import { Button } from '../../../ui/button';

const Tabs = ({ tabs, onTabChange, activeTab, main, useResponsiveDropdown = true }) => {
  const [selectedTab, setSelectedTab] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const dropdownRef = useRef(null);
  const previousTab = useRef(activeTab);

  useEffect(() => {
    if (activeTab !== previousTab.current) {
      setIsTransitioning(true);
      const timer = setTimeout(() => {
        setIsTransitioning(false);
      }, 300); // Match this with your CSS transition duration
      
      previousTab.current = activeTab;
      return () => clearTimeout(timer);
    }
    
    setSelectedTab(activeTab);
  }, [activeTab]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleTabChange = (tabId) => {
    if (tabId === selectedTab) return; // Don't re-select the same tab
    
    setIsTransitioning(true);
    setSelectedTab(tabId);
    setIsOpen(false);
    onTabChange(tabId);
  };

  const getSelectedTabLabel = () => {
    const tab = tabs.find(tab => tab.id === selectedTab);
    return tab ? (tab.label || tab.icon) : '';
  };

  // Add a transition class depending on state
  const transitionClass = isTransitioning ? 'opacity-50 transition-opacity duration-300' : 'opacity-100 transition-opacity duration-300';

  return (
    <>
      {useResponsiveDropdown && (
        <div className="sm:hidden bg-background" ref={dropdownRef}>
          <label htmlFor="Tab" className="sr-only">
            MenuTabs
          </label>

          {/* Custom dropdown */}
          <div className="relative">
            <Button
              variant="primary"
              size="md"
              className="w-full flex items-center justify-between rounded-md bg-background border-2 p-2 font-medium"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="!text-white">{getSelectedTabLabel()}</span>
              <svg className={`w-5 h-5 ml-2 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Button>
            
            {isOpen && (
              <div className="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg max-h-60 overflow-auto">
                <ul className="py-1">
                  {tabs.map((tab, index) => (
                    <li 
                      key={index} 
                      className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${selectedTab === tab.id ? 'bg-sky-50 text-sky-600' : 'text-gray-700'}`}
                      onClick={() => handleTabChange(tab.id)}
                    >
                      <div className="flex items-center">
                        {tab.icon && <span className="mr-2">{tab.icon}</span>}
                        <span className="text-sm">{tab.label}</span>
                        {tab.count && (
                          <span className={`ml-2 px-2 py-0.5 text-xs rounded-full ${tab.count.color}`}>
                            {tab.count.length > 99 ? '99+' : tab.count.length}
                          </span>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={`${useResponsiveDropdown ? 'hidden sm:block' : 'block'} ${main === true && 'border-b border-gray-200'} bg-background cursor-pointer select-none ${transitionClass}`}>
        <nav className={`flex items-center gap-6 bg-background ${main ? 'flex' : 'flex flex-col'}`} aria-label="Tabs">
          {tabs.map((tab, index) => (
            <TabButton
              key={index}
              icon={tab.icon}
              label={tab.label}
              selected={selectedTab === tab.id}
              main={main}
              onClick={() => handleTabChange(tab.id)}
              count={tab.count}
            />
          ))}
        </nav>
      </div>
    </>
  );
};

const TabButton = memo(({ icon, label, selected, onClick, main, count }) => {
  return (
    <div
      className={`px-4 ${main ? 'border-b-2 pb-2' : ''} ${icon && 'flex gap-2 items-center'} bg-background text-sm font-medium ${selected
        ? 'border-sky-500 text-sky-600'
        : 'border-transparent hover:border-gray-300'
        } transition-all duration-300`}
      onClick={onClick}
    >
      <div className={`flex gap-2 ${!selected && 'opacity-30'} relative transition-opacity duration-300`}>
        <span className={`flex gap-2 items-center ${!main && 'flex-col text-[15px]'}`}>{icon}{label}</span>
        {count && (
          <span className={`absolute right-[-15px] text-white top-[-10px] px-2 rounded-full text-[10px] text-bold ${count.color}`}>
            {count.length > 99 ? '99+' : count.length}
          </span>)}
      </div>
    </div>
  );
});

export default memo(Tabs);
