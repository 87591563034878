import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt, FaHeart, FaComment, FaPaperPlane, FaShareAlt, FaTrash, FaDownload } from 'react-icons/fa';
import { FaTiktok, FaFacebook, FaYoutube, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { SiThreads } from 'react-icons/si';
import { toast } from 'react-toastify';
import { Button } from '../../ui/button.js';
import { formatCustomDateTime } from '../../utils/date.js';
import { getFileTypeFromFirebasePath } from '../../utils/getFileType.js';
import useLocalStorage from 'use-local-storage';
import { LoadingIndicator } from '../Tools/LoadingIndicator.js';
import TikTokLoginButton from '../Buttons/TikTok/TikTokLoginButton';
import InstagramLoginButton from '../Buttons/Facebook/InstagramLoginButton';
import FacebookLoginButton from '../Buttons/Facebook/FacebookLoginButton';
import XLoginButton from '../Buttons/X/XLoginButton';
import YoutubeLoginButton from '../Buttons/Google/GoogleLoginButton';
import ThreadsLoginButton from '../Buttons/Threads/ThreadsLoginButton.js';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const ManageUpload = ({ upload, idToken }) => {
    const post = upload.posts ? upload.posts[0] : upload;
    const [likes, setLikes] = useState(0);
    const [comments, setComments] = useState(0);
    const [commentList, setCommentList] = useState([]);
    const [userSocial, setUserSocial] = useState('');
    const [activeWorkspace] = useLocalStorage('activeWorkspace');
    const hasRunOnceRef = useRef(false);
    const [commentText, setCommentText] = useState('');
    const [views, setViews] = useState(0);
    const [shares, setShares] = useState(0);
    const [permalink, setPermalink] = useState(null);
    const [showLoginButton, setShowLoginButton] = useState(false);
    // State to track which comment's reply input is visible
    const [visibleReplyInput, setVisibleReplyInput] = useState(null);

    const [isPosting, setIsPosting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [shouldFetchData, setShouldFetchData] = useState(false);
    const [isLoadingUserSocial, setIsLoadingUserSocial] = useState(false);

    const [showCommentInput, setShowCommentInput] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorDescription, setErrorDescription] = useState('');

    const toggleReplyInput = (commentId) => {
        setVisibleReplyInput(visibleReplyInput === commentId ? null : commentId);
    };

    // New function to fetch only user social data
    const fetchUserSocialData = async () => {
        if (!post.platform_id || !activeWorkspace || !idToken) {
            console.log('Missing required data for fetching user social');
            return;
        }

        setIsLoadingUserSocial(true);
        try {
            const logoResponse = await axios.get(`${BACKEND_API_URL}/api/auth/user/socials/${post.platform_id}`, {
                headers: { Authorization: `Bearer ${idToken}` },
                params: {
                    workspaceId: activeWorkspace.id
                }
            });
            
            setUserSocial(logoResponse.data.socialData);
        } catch (error) {
            console.error('Error fetching user social data:', error);
        } finally {
            setIsLoadingUserSocial(false);
        }
    };

    const fetchData = async () => {
        if (!post.media_id || !activeWorkspace || !idToken) {
            console.log('Missing required data for fetching');
            return;
        }

        setIsFetching(true);
        try {
            let mediaResponse = null;

            if (post.uploadTo !== 'tiktok') {
                // Fetch media data if not TikTok
                mediaResponse = await axios.get(`${BACKEND_API_URL}/api/${post.uploadTo}/${post.media_id}`, {
                    headers: { Authorization: `Bearer ${idToken}` },
                    params: {
                        workspaceId: activeWorkspace.id,
                        platform_id: post.platform_id
                    }
                });
            } else {
                setErrorDescription('Unfortunately, comments and insights are not supported for TikTok posts.');
            }

            if (post.uploadTo === 'instagram') {
                // Handle Instagram response
                setLikes(mediaResponse.data.media.like_count);
                setComments(mediaResponse.data.media.comments_count);
                setCommentList(mediaResponse.data.comments);
                setPermalink(mediaResponse.data.media.permalink);
            } else if (post.uploadTo === 'threads') {
                // Handle Threads response
                setLikes(mediaResponse.data.media.insights.likes);
                setComments(mediaResponse.data.media.insights.replies);
                setCommentList(mediaResponse.data.comments || []);
                setViews(mediaResponse.data.media.insights.views || 0);
                setShares(mediaResponse.data.media.insights.shares || 0);
            } else if (post.uploadTo === 'facebook') {
                // Handle Facebook response
                setLikes(mediaResponse.data.post.reactions.summary.total_count);
                setComments(mediaResponse.data.comments.length);

                // Normalize Facebook comments and replies to match the format used for other platforms
                const normalizedComments = mediaResponse.data.comments.map(comment => ({
                    id: comment.id,
                    text: comment.message,
                    username: comment.from.name,
                    timestamp: comment.created_time,
                    replies: comment.replies ? comment.replies.map(reply => ({
                        id: reply.id,
                        text: reply.message,
                        username: reply.from.name,
                        timestamp: reply.created_time
                    })) : [] // Initialize replies as an empty array if none
                }));
                setCommentList(normalizedComments);
                setPermalink(mediaResponse.data.post.permalink_url);
            } else if (post.uploadTo === 'x') {
                // Handle Twitter response
                setLikes(mediaResponse.data.tweet.likes);
                setComments(mediaResponse.data.replies.length);

                // Normalize Twitter replies to match the format used for other platforms
                const normalizedReplies = mediaResponse.data.replies.map(reply => ({
                    id: reply.id,
                    text: reply.text,
                    username: reply.author.username,
                    timestamp: new Date(reply.created_at).toLocaleString(),
                    replies: [] // Initialize replies as an empty array
                }));
                setCommentList(normalizedReplies);
                setPermalink(`https://twitter.com/${mediaResponse.data.tweet.author.username}/status/${mediaResponse.data.tweet.id}`);
            } else if (post.uploadTo === 'youtube') {
                // Handle YouTube response
                // Commenting out likes and comments count as they are not available in the response
                setLikes(mediaResponse.data.video.statistics.likeCount || 0);
                setComments(mediaResponse.data.comments.length);

                // Normalize YouTube comments to match the format used for other platforms
                const normalizedComments = mediaResponse.data.comments.map(comment => ({
                    id: comment.id,
                    text: comment.snippet.topLevelComment.snippet.textDisplay,
                    username: comment.snippet.topLevelComment.snippet.authorDisplayName,
                    timestamp: comment.snippet.topLevelComment.snippet.publishedAt,
                    replies: [] // Initialize replies as an empty array
                }));
                setCommentList(normalizedComments);
                setPermalink(`https://www.youtube.com/watch?v=${mediaResponse.data.video.id}`);
            }

            if (mediaResponse && mediaResponse.data) {
                setShowCommentInput(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setShowCommentInput(false);
                setErrorMessage(`Content not found at ${post.uploadTo}!`);
                setErrorDescription('Unfortunately, we couldnt find your comments or insights for this post. Check if the post is still available.');
            } else {
                toast.error(error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Failed to fetch data!');
            }
            if (error.response && error.response.status === 401) {
                setShowCommentInput(false);
                setErrorMessage(`Missing permissions at ${post.uploadTo}!`);
                setErrorDescription('We are missing permissions to fetch your comments or insights. Please check your social accounts, try reconnecting your accounts or try again later.');
                setShowLoginButton(true);
            }
            if (error.response && error.response.status === 429) {
                setShowCommentInput(false);
                setErrorMessage(`Too many requests at ${post.uploadTo}!`);
                setErrorDescription('We have exceeded the rate limit for this platform. Please try again later.');
            }
            console.error('Error fetching data:', error);
        } finally {
            setIsFetching(false);
        }
    };

    useEffect(() => {
        // Fetch user social data on component initialization
        if (post.platform_id && activeWorkspace && idToken) {
            fetchUserSocialData();
        }
        
        // Fetch comments and insights when requested
        if (shouldFetchData && post.media_id && activeWorkspace && idToken && !hasRunOnceRef.current) {
            hasRunOnceRef.current = true;
            fetchData();
        } else {
            console.log('Waiting for user to request data fetch');
        }
    }, [shouldFetchData, post.uploadTo, post.media_id, post.platform_id, activeWorkspace, idToken]);

    const postComment = async () => {
        if (!commentText.trim()) return;
        setIsPosting(true);
        try {
            const response = await axios.post(`${BACKEND_API_URL}/api/${post.uploadTo}/comment`, {
                media_id: post.media_id,
                platform_id: post.platform_id,
                workspaceId: activeWorkspace.id,
                comment: commentText
            }, {
                headers: { Authorization: `Bearer ${idToken}` }
            });

            if (response.data.success) {
                setCommentList([...commentList, { id: response.data.commentId, username: userSocial.username, text: commentText, timestamp: response.data.timestamp || new Date() }]);
                setCommentText('');
            } else {
                console.error('Failed to post comment');
            }
        } catch (error) {
            toast.error(error.response.data.error ? error.response.data.error : 'Failed to post reply');
            console.error('Error posting comment:', error);
        } finally {
            setIsPosting(false);
        }
    };

    const postReply = async (commentId, replyText) => {
        if (!replyText.trim()) return;
        setIsPosting(true);
        try {
            const response = await axios.post(`${BACKEND_API_URL}/api/${post.uploadTo}/comment/${commentId}/reply`, {
                media_id: post.media_id,
                platform_id: post.platform_id,
                workspaceId: activeWorkspace.id,
                reply: replyText
            }, {
                headers: { Authorization: `Bearer ${idToken}` }
            });

            if (response.data.success) {
                const newCommentList = commentList.map(c =>
                    c.id === commentId ? { ...c, replies: [...(c.replies || []), { username: userSocial.username, text: replyText }] } : c
                );
                setCommentList(newCommentList);
                setCommentText('');
            } else {
                console.error('Failed to post reply');
            }
        } catch (error) {
            toast.error(error.response.data.error ? error.response.data.error : 'Failed to post reply');
            console.error('Error posting reply:', error);
        } finally {
            setIsPosting(false);
        }
    };

    const deleteComment = async (commentId) => {
        if (!idToken) return;
        setIsDeleting(true);
        try {
            const response = await axios.delete(`${BACKEND_API_URL}/api/${post.uploadTo}/comment/${commentId}`, {
                headers: { Authorization: `Bearer ${idToken}` },
                params: {
                    workspaceId: activeWorkspace.id,
                    platform_id: post.platform_id
                }
            });

            if (response.data.success) {
                setCommentList(commentList.filter(comment => comment.id !== commentId));
            } else {
                console.error('Failed to delete comment');
            }
        } catch (error) {
            toast.error(error.response.data.error ? error.response.data.error : 'Failed to delete comment');
            console.error('Error deleting comment:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div className="p-6 rounded-lg text-center flex flex-col">
            <div className="p-4 pb-0 flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <div className="relative w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center">
                        <img
                            src={userSocial.logo || `${process.env.PUBLIC_URL}/random/no-image.png`}
                            alt="Platform Logo"
                            className="w-full h-full object-cover rounded-full"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `${process.env.PUBLIC_URL}/random/no-image.png`;
                            }}
                        />
                        <div className="absolute bottom-0 right-0 bg-white p-1 rounded-full shadow-md z-10">
                            {post.uploadTo === 'tiktok' && <FaTiktok className="w-4 h-4 text-black" />}
                            {post.uploadTo === 'instagram' && <FaInstagram className="w-4 h-4 text-pink-500" />}
                            {post.uploadTo === 'facebook' && <FaFacebook className="w-4 h-4 text-blue-600" />}
                            {post.uploadTo === 'x' && <FaXTwitter className="w-4 h-4 text-black" />}
                            {post.uploadTo === 'youtube' && <FaYoutube className="w-4 h-4 text-red-600" />}
                            {post.uploadTo === 'threads' && <SiThreads className="w-4 h-4 text-black" />}
                        </div>
                    </div>
                    <h2 className="text-lg font-semibold self-end mb-2">{userSocial.username}</h2>
                </div>
                <div className="flex flex-col items-center justify-center gap-2">
                    <p className="text-[10px] text-gray-500">{formatCustomDateTime(post.updatedAt)} · 🌍</p>
                    <Link to={permalink || `https://${post.uploadTo}.com/`} target="_blank" rel="noopener noreferrer">
                        <Button variant="primary" className="text-gray-500 hover:text-gray-700">
                            <FaExternalLinkAlt className="w-4 h-4" />
                        </Button>
                    </Link>
                </div>
            </div>

            <div className="p-4 text-left bg-gray-100 rounded-md">
                <h3 className="text-md font-bold mb-2">{post.title}</h3>
                <p className="text-gray-700 mb-4 text-sm">{post.description}</p>
            </div>

            {post.firebase_path && (
                <div className="relative aspect-video mt-4 max-w-full min-h-[300px] max-h-[500px] rounded-md overflow-hidden">
                    {getFileTypeFromFirebasePath(post.firebase_path) === 'video' ? (
                        <video controls className="object-cover w-full h-full">
                            <source src={post.firebase_path} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <img
                            src={post.firebase_path}
                            alt="Post media"
                            className="object-cover w-full h-full"
                        />
                    )}
                </div>
            )}

            <div className="p-4">
                {!shouldFetchData ? (
                    <div className="flex justify-center mb-4">
                        <Button 
                            variant="outline" 
                            className="flex items-center gap-2 text-blue-500 hover:text-blue-700" 
                            onClick={() => setShouldFetchData(true)}
                            disabled={isFetching}
                        >
                            {isFetching ? (
                                <>
                                    <LoadingIndicator showLogo={false} size={8} />
                                    <span>Loading...</span>
                                </>
                            ) : (
                                <>
                                    <FaDownload className="w-4 h-4" />
                                    Fetch Comments & Insights
                                </>
                            )}
                        </Button>
                    </div>
                ) : isFetching ? (
                    <div className="flex justify-center mb-4">
                        <LoadingIndicator showLogo={false} size={8} />
                        <span className="ml-2 text-gray-500">Loading data...</span>
                    </div>
                ) : (
                    <div className="flex items-center justify-between text-sm text-gray-500">
                        <div className="flex items-center gap-2">
                            <div className="bg-blue-500 rounded-full p-1">
                                <FaHeart className="w-3 h-3 text-white" />
                            </div>
                            <span>{likes}</span>
                        </div>
                        <div className="flex items-center gap-5 px-5">
                            <div>{comments} comments</div>
                            <span>{shares} shares</span>
                            <span>{views} views</span>
                        </div>
                    </div>
                )}

                <hr className="my-4" />

                <div className="flex items-center justify-between">
                    <div variant="outline" className="flex-1 flex items-center justify-center gap-2 text-gray-500 cursor-default">
                        <FaHeart className="w-5 h-5" />
                        Like
                    </div>
                    <div variant="outline" className="flex-1 flex items-center justify-center gap-2 text-gray-500 cursor-default">
                        <FaComment className="w-5 h-5" />
                        Comment
                    </div>
                    <div variant="ghost" className="flex-1 flex items-center justify-center gap-2 text-gray-500 cursor-default">
                        <FaPaperPlane className="w-5 h-5" />
                        Send
                    </div>
                    <div variant="outline" className="flex-1 flex items-center justify-center gap-2 text-gray-500 cursor-default">
                        <FaShareAlt className="w-5 h-5" />
                        Share
                    </div>
                </div>

                <hr className="my-4" />

                {shouldFetchData && !isFetching && (
                    <>
                        <div className="max-h-[200px] h-max overflow-y-auto pr-4">
                            {commentList.map((comment) => (
                                <div key={comment.id} className="flex flex-col mb-4">
                                    <div className="flex items-start gap-2">
                                        <div className="w-8 h-8 rounded-full bg-blue-200 flex items-center justify-center">
                                            <span className="text-white font-bold">
                                                {comment.username ? comment.username.charAt(0).toUpperCase() : '?'}
                                            </span>
                                        </div>
                                        <div className="bg-gray-100 rounded-2xl px-4 py-2 w-full flex flex-col gap-2 justify-start items-start">
                                            <p className="font-semibold text-sm">{comment.username}</p>
                                            <p className="text-sm">{comment.text}</p>
                                            <div className="flex items-center gap-5 w-full">
                                                {comment.timestamp && <p className="text-sm text-[10px] text-gray-500 w-max">{formatCustomDateTime(comment.timestamp)}</p>}
                                                <span
                                                    variant="link"
                                                    onClick={() => toggleReplyInput(comment.id)}
                                                    className="text-blue-500 text-xs mt-1 cursor-pointer"
                                                >
                                                    Reply
                                                </span>
                                                {/* Conditionally render the reply input */}
                                                {visibleReplyInput === comment.id ? (
                                                    <div className="flex items-center gap-2 mt-2 ml-8 bg-white rounded-full px-2 flex-1 w-full">
                                                        <input
                                                            type="text"
                                                            placeholder="Write a reply..."
                                                            value={comment.replyText || ''}
                                                            onChange={(e) => {
                                                                const newCommentList = commentList.map(c =>
                                                                    c.id === comment.id ? { ...c, replyText: e.target.value } : c
                                                                );
                                                                setCommentList(newCommentList);
                                                            }}
                                                            className="border-0 rounded-full px-2 flex-1 text-xs h-full py-2"
                                                        />
                                                        <Button
                                                            onClick={() => postReply(comment.id, comment.replyText)}
                                                            variant="outline"
                                                            className="text-gray-500 hover:text-gray-700"
                                                            disabled={isPosting}
                                                        >
                                                            {isPosting ? <LoadingIndicator showLogo={false} size={5} /> : <FaPaperPlane className="w-4 h-4" />}
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div className='flex-1'></div>
                                                )}
                                                {post.uploadTo !== 'threads' && (
                                                    <span
                                                        variant="link"
                                                        onClick={() => !isDeleting && deleteComment(comment.id)}
                                                        className={`text-red-500 text-xs mt-1 cursor-pointer ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                    >
                                                        {isDeleting ? <LoadingIndicator showLogo={false} size={5} /> : <FaTrash className='w-4 h-4' />}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Render replies */}
                                    {comment.replies && comment.replies.length > 0 && (
                                        <div className="ml-10 mt-2">
                                            {comment.replies.map((reply) => (
                                                <div key={reply.id} className="flex items-start gap-2 mb-2">
                                                    <div className="w-8 h-8 rounded-full bg-blue-200 flex items-center justify-center">
                                                        <span className="text-white font-bold">
                                                            {reply.username.charAt(0).toUpperCase()}
                                                        </span>
                                                    </div>
                                                    <div className="bg-gray-100 rounded-2xl px-4 py-2 flex flex-col gap-2 justify-start items-start w-full  ">
                                                        <p className="font-semibold text-sm">{reply.username}</p>
                                                        <p className="text-sm">{reply.text}</p>
                                                        {reply.timestamp && <p className="text-sm text-[10px] text-gray-500 w-max">{formatCustomDateTime(reply.timestamp)}</p>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>

                        {!showCommentInput && <p className="text-red-500 text-sm">{errorMessage}</p>}

                        {showCommentInput && post.uploadTo !== 'tiktok' ? (
                            <div className="flex-1 flex items-center gap-2 bg-gray-100 rounded-full px-4 py-2">
                                <input
                                    type="text"
                                    placeholder="Write a comment..."
                                    value={commentText}
                                    onChange={(e) => setCommentText(e.target.value)}
                                    className="border-0 bg-transparent p-0 focus:ring-0 w-full h-full py-2"
                                />
                                <Button onClick={postComment} variant="outline" className="text-gray-500 hover:text-gray-700" disabled={isPosting}>
                                    {isPosting ? <LoadingIndicator showLogo={false} size={5} /> : <FaPaperPlane className="w-5 h-5" />}
                                </Button>
                            </div>
                        ) : (
                            <p className="text-gray-500 text-sm">{errorDescription}</p>
                        )}
                    </>
                )}
            </div>

            {showLoginButton && shouldFetchData && (
                <div className="mt-4 flex flex-col w-2/3 mx-auto">
                    <p className="text-gray-500 text-sm mb-1 italic">Please Login to view comments and insights.</p>
                    {post.uploadTo === 'tiktok' && <TikTokLoginButton />}
                    {post.uploadTo === 'youtube' && <YoutubeLoginButton />}
                    {post.uploadTo === 'instagram' && <InstagramLoginButton />}
                    {post.uploadTo === 'facebook' && <FacebookLoginButton />}
                    {post.uploadTo === 'x' && <XLoginButton />}
                    {post.uploadTo === 'threads' && <ThreadsLoginButton />}
                </div>
            )}
        </div>
    );
};

export default ManageUpload; 