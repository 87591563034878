import React, { useState, useRef, useEffect, useCallback } from 'react';
import './VideoUploader.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import UploadForm from './UploadForm/UploadForm';
import sendEmail from '../Tools/SendEmail';
import VideoErrorUpload from '../../emails/VideoErrorUpload.tsx';
import { render } from '@react-email/components';
import { extractFolderAndFileName, uploadFileToFirebase } from '../../utils/firebase.js';
import { getFileType, getFileTypeFromFirebasePath } from '../../utils/getFileType.js';
import { v4 as uuidv4 } from 'uuid';
import { checkImagePropertie } from '../../utils/metadataCheck.js';
import { useWorkspace } from '../../context/WorkspaceContext';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const VideoUploader = ({ draft, closeScheduleModal, uploadType }) => {
  const navigate = useNavigate();
  const hasRunOnceRef = useRef(false);
  const [localVideoPath, setLocalVideoPath] = useState(null);
  const [firebaseVideoPath, setFirebaseVideoPath] = useState(null);
  const [firebaseThumbPath, setFirebaseThumbPath] = useState(null);
  const [formData, setFormData] = useState({});
  const uniquePostId = uuidv4();
  const { activeWorkspace, idToken } = useWorkspace();


  const handleEditDraft = useCallback(async (group) => {
    const updatedFormData = group.posts.map((data) => {
      const post = group.posts.find(post => post.uploadTo === data.uploadTo);
      if (post) {
        const draftScheduleDate = new Date(post.schedule);
        if (draftScheduleDate > new Date()) {
          post.schedule = draftScheduleDate;
        }
        const newFormData = {
          draftId: post._id,
          postId: post.post_id,
          platformId: post.platform_id,
          title: post.title,
          description: post.description,
          privacyStatus: post.privacy_status,
          schedule: post.schedule,
          uploadType: post.uploadType,
          uploadTo: post.uploadTo,
          status: post.status,
          firebasePath: post.firebase_path,
          videoPath: post.video_path,
          hasBeenEdited: true,
          comment: post.comment,
        };
        setFirebaseThumbPath(post.thumbnail);
        setFirebaseVideoPath(post.firebase_path);
        setLocalVideoPath(post.video_path);
        return newFormData;
      } else {
        return data;
      }
    });
    setFormData(updatedFormData);
  }, []);

  useEffect(() => {
    if (!hasRunOnceRef.current && draft) {
      if (draft.posts.length > 0) {
        handleEditDraft(draft);
      }
      hasRunOnceRef.current = true;
    }
  }, [draft, handleEditDraft]);

  const handleUploadButtonClick = async (uploadData) => {
    if (uploadData.uploadTo === 'draft') return;

    const uploadPromises = [];
    let thumb = formData.thumbnail;

    try {
      if (getFileType(uploadData.uploadType) === 'video' && (!localVideoPath && !uploadData.videoPath)) {
        toast.warning('No video uploaded...');
        return;
      }

      if (getFileType(uploadData.uploadType) === 'image' && (!localVideoPath && !uploadData.videoPath && uploadData.uploadTo === 'instagram')) {
        toast.warning('An image is required for instagram uploads.');
        return;
      }

      if (thumb && typeof thumb !== 'string') {
        thumb = await uploadFileToFirebase(thumb, 'img', null, activeWorkspace.id);
      }

      if (uploadData.uploadTo) {
        uploadPromises.push(handlePlatformUpload(uploadData));
      }

      navigate('/dashboard');
      const toastId = toast.loading('Uploading to ' + uploadData.uploadTo);

      let promises = await Promise.all(uploadPromises);
      promises.forEach((result) => {
        toast.update(toastId, {
          render: result.message,
          type: result.success ? 'success' : 'error',
          isLoading: false,
          autoClose: 5000,
        });
      });

    } catch (error) {
      console.error('UPLOAD API ERROR:', error);
    }
  };

  const handlePlatformUpload = async (uploadData) => {
    switch (uploadData.uploadTo) {
      case 'youtube':
        return uploadToYoutube(uploadData);
      case 'tiktok':
        return uploadToTiktok(uploadData);
      case 'instagram':
        return uploadToInstagram(uploadData);
      case 'facebook':
        return uploadToFacebook(uploadData);
      case 'x':
        return uploadToX(uploadData);
      case 'threads':
        return uploadToThreads(uploadData);
      default:
        return { success: false, message: 'Unsupported platform' };

    }
  };

  const uploadToThreads = async (uploadData) => {
    let mediaType = '';
    if (getFileTypeFromFirebasePath(uploadData.firebasePath) === 'video' || getFileTypeFromFirebasePath(firebaseVideoPath) === 'video') {
      mediaType = 'VIDEO';
    } else if (getFileTypeFromFirebasePath(uploadData.firebasePath) === 'image' || getFileTypeFromFirebasePath(firebaseVideoPath) === 'image') {
      mediaType = 'IMAGE';
    }

    if (!firebaseVideoPath && !localVideoPath && !formData.videoPath && !uploadData.videoPath) {
      mediaType = 'TEXT';
    }

    const data = {
      id: uploadData.draftId,
      description: uploadData.description,
      platform_id: uploadData.platformId,
      postId: uploadData.postId,
      mediaType: mediaType,
      uploadTo: uploadData.uploadTo,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      uploadType: uploadData.uploadType,
      workspaceId: activeWorkspace.id,
      comment: uploadData.comment,
    };

    try {
      const response = await axios.post(BACKEND_API_URL + '/api/threads/upload', { data }, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      if (response.status === 201) {
        return {
          success: true,
          message: `Your content has been uploaded to ${uploadData.uploadTo}!`,
        };
      }
    } catch (error) {
      console.log(error);
      handleErrorUpload(data);
      return {

        success: false,
        message: `An error occurred while uploading to ${uploadData.uploadTo}!`,
      };
    }
  };

  const uploadToYoutube = async (uploadData) => {
    if (!localVideoPath && !uploadData.videoPath) {

      return {
        success: true,
        message: `No video found...`,
      };
    }

    const data = {
      id: uploadData.draftId,
      title: uploadData.title,
      desc: uploadData.description,
      privacyStatus: uploadData.privacyStatus,
      platform_id: uploadData.platformId,
      postId: uploadData.postId,
      uploadTo: uploadData.uploadTo,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      uploadType: uploadData.uploadType,
      workspaceId: activeWorkspace.id,
      comment: uploadData.comment,
    };
    try {
      const response = await axios.post(BACKEND_API_URL + '/api/google/upload_video', { data }, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      if (response.status === 201)
        return {
          success: true,
          message: `Your content has been uploaded to ${uploadData.uploadTo}!`,
        };
    } catch (error) {
      if (error) {
        handleErrorUpload(data);
        return {
          success: false,
          message: `An error occurred while uploading to ${uploadData.uploadTo}!`,
        };
      }
    }
  };

  const uploadToTiktok = async (uploadData) => {
    let privacy = '';
    if (uploadData.privacyStatus === "private") {
      privacy = "SELF_ONLY"
    } else if (uploadData.privacyStatus === "public") {
      privacy = "PUBLIC_TO_EVERYONE"
    }

    const data = {
      id: uploadData.draftId,
      postId: uploadData.postId,
      description: uploadData.description,
      privacyStatus: privacy,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      platform_id: uploadData.platformId,
      allowComment: uploadData.commentEnabled,
      allowDuet: uploadData.duetEnabled,
      allowStitch: uploadData.stitchEnabled,
      selfBrand: uploadData.isOwnBrand,
      uploadTo: uploadData.uploadTo,
      contentBrand: uploadData.isBranded,
      uploadType: uploadData.uploadType,
      workspaceId: activeWorkspace.id,
      comment: uploadData.comment,
    };
    try {
      let response;

      if (getFileTypeFromFirebasePath(uploadData.firebasePath) === 'image' || getFileTypeFromFirebasePath(firebaseVideoPath) === 'image') {
        response = await axios.post(BACKEND_API_URL + '/api/tiktok/init_photo_upload', { data }, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
      } else {
        response = await axios.post(BACKEND_API_URL + '/api/tiktok/init_upload', { data }, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
      }
      if (response.status === 201)
        return {
          success: true,
          message: `Your content has been uploaded to ${uploadData.uploadTo}!`,
        };
    } catch (error) {
      if (error) {
        handleErrorUpload(data);
        return {
          success: false,
          message: `An error occurred while uploading to ${uploadData.uploadTo}!`,
        };
      }
    }
  };

  const uploadToInstagram = async (uploadData) => {
    if (!localVideoPath && !uploadData.videoPath) {
      return {
        success: true,
        message: `No video found...`,
      };
    }

    let endpoint = '';

    if (getFileTypeFromFirebasePath(uploadData.firebasePath) === 'video' || getFileTypeFromFirebasePath(firebaseVideoPath) === 'video') {
      if (uploadData.uploadType === 'STORIES') uploadData.uploadType = 'STORIES'
      else uploadData.uploadType = 'REELS'


      endpoint = '/api/instagram/upload_video'
    }
    if (getFileTypeFromFirebasePath(uploadData.firebasePath) === 'image' || getFileTypeFromFirebasePath(firebaseVideoPath) === 'image') {
      endpoint = '/api/instagram/upload_image'
    }


    const data = {
      id: uploadData.draftId,
      description: uploadData.description,
      platform_id: uploadData.platformId,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      uploadType: uploadData.uploadType,
      postId: uploadData.postId,
      uploadTo: uploadData.uploadTo,
      workspaceId: activeWorkspace.id,
      comment: uploadData.comment,
    };

    try {
      if (getFileTypeFromFirebasePath(uploadData.firebasePath) === 'image' || getFileTypeFromFirebasePath(firebaseVideoPath) === 'image') {
        const { aspectRatio, width } = await checkImagePropertie(data.firebasePath || data.video_path);


        if (aspectRatio < 0.8 || aspectRatio > 1.91) {
          return {
            success: false,
            message: `The image aspect ratio for instagram must be between 4:5 (0.8) and 1.91:1 (1.91). Your image has an aspect ratio of ${aspectRatio.toFixed(2)}.`,
          };
        }

        if (width < 320 || width > 1440) {
          return {
            success: false,
            message: `The image width must be between 320px and 1440px. Your image width is ${width}px.`,
          };
        }
      }

      const response = await axios.post(BACKEND_API_URL + endpoint, { data }, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      if (response.status === 201)
        return {
          success: true,
          message: `Your content has been uploaded to ${uploadData.uploadTo}!`,
        };
    } catch (error) {
      console.log(error);

      if (error) {
        handleErrorUpload(data);
        return {
          success: false,
          message: `An error occurred while uploading to ${uploadData.uploadTo}!`,
        };
      }
    }
  };

  const uploadToFacebook = async (uploadData) => {
    const data = {
      id: uploadData.draftId,
      title: uploadData.title,
      description: uploadData.description,
      platform_id: uploadData.platformId,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      postId: uploadData.postId,
      uploadTo: uploadData.uploadTo,
      uploadType: uploadData.uploadType,
      workspaceId: activeWorkspace.id,
      comment: uploadData.comment,
    };

    let endpoint = '';

    if (getFileTypeFromFirebasePath(uploadData.firebasePath) === 'video' || getFileTypeFromFirebasePath(firebaseVideoPath) === 'video') {
      if (uploadData.uploadType === 'reel') {
        endpoint = '/api/facebook/upload_reel';




      } else {
        endpoint = '/api/facebook/upload_video';
      }
    }
    else {
      endpoint = '/api/facebook/upload_post';
    }

    try {
      const response = await axios.post(
        BACKEND_API_URL + endpoint,
        { data },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      if (response.status === 201) {
        return {
          success: true,
          message: `Your content has been uploaded to ${uploadData.uploadTo}!`,
        };
      }
    } catch (error) {
      handleErrorUpload(data);
      return {
        success: false,
        message: `An error occurred while uploading to ${uploadData.uploadTo}!`,
      };
    }
  };

  const uploadToX = async (uploadData) => {
    if (!uploadData.description && !localVideoPath && !uploadData.videoPath) {
      return {
        success: false,
        message: `No content found for the tweet...`,
      };
    }

    const data = {
      id: uploadData.draftId,
      description: uploadData.description,
      platform_id: uploadData.platformId,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      uploadType: uploadData.uploadType,
      postId: uploadData.postId,
      uploadTo: uploadData.uploadTo,
      workspaceId: activeWorkspace.id,
      comment: uploadData.comment,
    };

    try {
      const response = await axios.post(BACKEND_API_URL + '/api/x/upload_post', { data }, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      if (response.status === 201)
        return {
          success: true,
          message: `Your tweet has been posted to ${uploadData.uploadTo}!`,
        };
    } catch (error) {
      console.error('UPLOAD API ERROR:', error);
      handleErrorUpload(data);
      return {
        success: false,
        message: `An error occurred while posting to ${uploadData.uploadTo}!`,
      };
    }
  };

  const handleErrorUpload = async (data) => {
    try {
      const res = await axios.post(BACKEND_API_URL + '/api/auth/user/uploads/error', data, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      if (res.status !== 201) return;

      const userSettingsResponse = await axios.get(BACKEND_API_URL + '/api/auth/user/settings', { params: { workspaceId: activeWorkspace.id }, headers: { Authorization: `Bearer ${idToken}` } });
      if (userSettingsResponse.status !== 201) return;

      const userSettings = userSettingsResponse.data;
      if (userSettings.notifications?.isActive && userSettings.notifications?.errorNotifications) {
        const emailHtml = render(<VideoErrorUpload upload={data} />);
        await sendEmail('Important: Video Upload Failed on FrenzyPost', emailHtml);
      }
    } catch (error) {
      console.log(error);
    }

    if (data.platform_id === undefined) return;
    const platform_id = data.platform_id;
    try {
      await axios.post(BACKEND_API_URL + '/api/auth/user/social/error', { platform_id, workspaceId: activeWorkspace.id }, { headers: { Authorization: `Bearer ${idToken}` } });
    } catch (error) {
      console.log(error);
    }
  };

  const saveToDraft = async (isSchedule = false, uploadData) => {
    let thumb = uploadData.thumbnail;

    if (thumb && typeof thumb !== 'string') {
      thumb = await uploadFileToFirebase(uploadData.thumbnail, 'img', null, activeWorkspace.id);
    }

    if (uploadData.status === 'uploaded') {
      uploadData.postId = uniquePostId;
      uploadData.draftId = null;
    }

    const data = {
      id: uploadData.draftId || undefined,
      post_id: uploadData.postId,
      platform_id: uploadData.platformId,
      title: uploadData.title,
      description: uploadData.description,
      uploadType: uploadData.uploadType || '',
      privacy_status: uploadData.privacyStatus || 'private',
      video_path: localVideoPath || formData.videoPath,
      firebase_path: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      status: isSchedule ? 'scheduled' : 'draft',
      uploadTo: uploadData.uploadTo,
      schedule: isSchedule ? uploadData.schedule : null,
      workspaceId: activeWorkspace.id,
      comment: uploadData.comment,
    };

    try {
      await axios.post(BACKEND_API_URL + '/api/auth/user/drafts', { data }, { headers: { Authorization: `Bearer ${idToken}` } });
      toast.success(isSchedule ? 'Your post has been scheduled!' : 'Your post has been saved to your drafts!');
      if (isSchedule && typeof closeScheduleModal === 'function') {
        closeScheduleModal();
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      toast.error(isSchedule ? 'Failed to schedule your post, please try again!' : 'Failed to save your post to drafts, please try again!');
    }
  };

  const handleScheduleUpload = (uploadData) => {
    if (uploadData.uploadTo === 'draft') return;
    saveToDraft(true, uploadData);
  };

  const handleFormAction = async (action, data) => {
    if (data.length === 0) {
      toast.warning('There has been no changed made!');
      return;
    }
    for (const platform of data) {
      if (action === 'upload') {
        handleUploadButtonClick(platform);
      } else if (action === 'schedule') {
        handleScheduleUpload(platform);
      } else if (action === 'draft') {
        saveToDraft(false, platform);
      }
    }
  };

  const handleFileSelect = (firebaseVideoPath, firebaseThumbnail) => {
    const localPath = extractFolderAndFileName(firebaseVideoPath);
    setLocalVideoPath(localPath);
    setFirebaseVideoPath(firebaseVideoPath);
    setFirebaseThumbPath(firebaseThumbnail);
  };
  return (
    <div className='flex flex-col w-full h-[calc(100vh-77.5px)]'>
      <UploadForm
        draftFormData={formData}
        onFormAction={handleFormAction}
        video={{ localVideo: localVideoPath, firebasePath: firebaseVideoPath, thumbnail: firebaseThumbPath }}
        uploadType={uploadType}
        onFileSelect={handleFileSelect}
      />
    </div>
  );
};

export default VideoUploader;