import React, { useEffect, useRef, useState } from 'react'
import CustomRadioButtons from '../../Tools/Radio/CustomRadioButtons'
import LinkUpload from '../LinkUpload/LinkUpload';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { ProgressWheel } from '../../Tools/LoadingIndicator';
import { uploadFileToFirebase } from '../../../utils/firebase';
import { useWorkspace } from '../../../context/WorkspaceContext';

function UploadArea({ onFileSelect, uploadType }) {
    const [selectedUploadType, setSelectedUploadType] = useState('File');
    const fileInputRef = useRef(null);
    const maxSizeBytes = 2 * 1024 * 1024 * 1024; // 2 GB
    const [selectedFile, setSelectedFile] = useState(null);
    const [localFilePath, setLocalFilePath] = useState(null);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [onUploadProgress, setOnUploadProgress] = useState(false);
    const { activeWorkspace } = useWorkspace();


    // Define supported types based on uploadType
    const videoTypes = ['video/mp4', 'video/avi', 'video/mov', 'video/wmv'];
    const imageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];

    // Determine the supported types and accept attribute
    let supportedTypes = [];
    let acceptTypes = '';
    let allowedFormatsText = '';
    if (uploadType === 'video') {
        supportedTypes = videoTypes;
        acceptTypes = 'video/*';
        allowedFormatsText = 'Allowed formats: .mp4, .avi, .mov, .wmv (max size 2GB)';
    } else if (uploadType === 'post') {
        supportedTypes = imageTypes;
        acceptTypes = 'image/*';
        allowedFormatsText = 'Allowed formats: .jpg, .jpeg, .png (max size 2GB)';
    } else {
        // Default to all types if uploadType is not specified
        supportedTypes = [...videoTypes, ...imageTypes];
        acceptTypes = 'video/*,image/*';
        allowedFormatsText = 'Allowed formats: .mp4, .avi, .mov, .wmv, .jpg, .jpeg, .png (max size 2GB)';
    }


    useEffect(() => {
        if (localFilePath && !loadingUpload && activeWorkspace) {

            (async () => {
                try {
                    let fileType = 'video';
                    if (['image', 'img', 'post'].includes(uploadType)) {
                        fileType = 'img';
                    }
                    setLoadingUpload(true);
                    const downloadUrl = await uploadFileToFirebase(localFilePath, fileType, setOnUploadProgress, activeWorkspace.id);
                    onFileSelect(downloadUrl);
                    setLoadingUpload(false);
                    setLocalFilePath(null);
                } catch (error) {
                    console.error('Failed to upload file to server:', error);
                    setLoadingUpload(false);
                }
            })();
        }
    }, [localFilePath, onFileSelect, uploadType, loadingUpload, activeWorkspace]);

    useEffect(() => {
        // Cleanup: revoke object URLs to prevent memory leaks
        return () => {
            if (selectedFile && typeof selectedFile === 'string') {
                URL.revokeObjectURL(selectedFile);
            }
        };
    }, [selectedFile]);

    // Handle link upload from LinkUpload component
    const handleLinkUpload = (path, localPath) => {
        setSelectedFile(path);
        setLocalFilePath(localPath);
    };

    // Handle selection change between 'File' and 'Url', debounced to avoid rapid state updates
    const handleSelectionChange = debounce((newValue) => {
        setSelectedUploadType(newValue);
    }, 500);

    // Handle file input change (when a file is selected via the file input)
    const handleFileChange = async (e) => {
        e.preventDefault();

        const file = e.target.files[0];

        if (!file) return;

        // Check if the file type is supported
        if (!supportedTypes.includes(file.type)) {
            toast.error('Unsupported file type');
            return;
        }

        // Check if the file size exceeds the maximum allowed size
        if (file.size > maxSizeBytes) {
            toast.error('File size exceeds the maximum allowed size (2 GB)');
        } else {
            // Set the selected file and its local path
            const fileURL = URL.createObjectURL(file);
            setSelectedFile(fileURL);
            setLocalFilePath(file);
        }
    };

    // Handle button click to open the file input dialog
    const handleSelectButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Handle file drop event (when a file is dropped onto the upload area)
    const handleDrop = (e) => {
        e.preventDefault();

        const file = e.dataTransfer.files[0];

        if (!file) return;

        // Check if the file type is supported
        if (!supportedTypes.includes(file.type)) {
            toast.error('Unsupported file type');
            return;
        }

        // Check if the file size exceeds the maximum allowed size
        if (file.size > maxSizeBytes) {
            toast.error('File size exceeds the maximum allowed size (2 GB)');
        } else {
            // Set the selected file and its local path
            const fileURL = URL.createObjectURL(file);
            setSelectedFile(fileURL);
            setLocalFilePath(file);
        }
    };

    // Prevent default behavior when dragging a file over the upload area
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    // Content for the radio buttons to select between 'File' and 'Url'
    const radioContent = [
        {
            label: 'File',
            showCheckbox: false
        },
        {
            label: 'Url',
            showCheckbox: false,
        }
    ];

    return (
        <div className='min-w-full h-full'>
            {/* Render the radio buttons for selecting upload type */}
            {loadingUpload && !selectedFile && (
                <div className="nav-menu flex w-max mx-auto mb-5">
                    <CustomRadioButtons content={radioContent} onSelectionChange={handleSelectionChange} />
                </div>
            )}

            {loadingUpload ? (
                <div className='flex justify-center items-center w-full'>
                    <ProgressWheel progress={onUploadProgress} size={100} />
                </div>
            ) : (
                // Render the file upload area if 'File' is selected
                selectedUploadType === 'File' && (
                    <div
                        className="upload-area h-full cursor-pointer"
                        onClick={handleSelectButtonClick}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                    >
                        <div className="flex flex-col items-center space-y-2 my-8">
                            {/* Adjust the image based on uploadType */}
                            <img
                                src={`${process.env.PUBLIC_URL}/random/${uploadType === 'video' ? 'video-upload.png' : 'image-upload.svg'}`}
                                className={`${uploadType === 'video' ? 'w-[15%]' : 'w-[20%]'}`}
                                alt="Upload"
                            />
                            <div className="flex flex-col items-center">
                                {/* Change the descriptive text based on uploadType */}
                                <p className="font-semibold text-[18px]">
                                    {uploadType === 'video' ? 'Upload your video' : 'Upload your image'}
                                </p>
                                <p className="text-[12px]">Drag & Drop or select a file</p>
                                <p className="text-[10px] !mt-5">
                                    {allowedFormatsText}
                                </p>
                            </div>
                        </div>

                        {/* Hidden file input for selecting files */}
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept={acceptTypes}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                    </div>
                )
            )}

            {/* Render the LinkUpload component if 'Url' is selected */}
            {selectedUploadType === 'Url' && <LinkUpload onVideoPath={handleLinkUpload} />}
        </div>
    )
}

export default UploadArea;
