import React, { useEffect, useRef, useState, useCallback } from 'react';
import Tabs from '../../Navigation/MenuTabs/Tabs';
import { FaArrowDown, FaBookmark, FaComment, FaEye, FaHeart, FaRegBookmark, FaUpload, FaWhatsapp } from 'react-icons/fa';
import { LuSend } from "react-icons/lu";
import { CiWarning } from "react-icons/ci";
import { getFileType } from '../../../utils/getFileType';
import { MdError, MdVerified, MdWarning } from 'react-icons/md';
import { GoDotFill } from 'react-icons/go';
import { IoEllipsisHorizontal } from 'react-icons/io5';
import { AiFillLike } from 'react-icons/ai';
import { MdPublic, MdLock, MdVisibilityOff } from 'react-icons/md';
import { BiRepost, BiSolidBarChartAlt2 } from 'react-icons/bi';

const VideoPreview = React.memo(({ draftVideo, uploadType, formData, formTabs, userSocials, activeMenuForm, onError }) => {
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [firebasePath, setFirebasePath] = useState(draftVideo);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activePreviewSocialMenu, setActivePreviewSocialMenu] = useState(activeMenuForm);
  const [previewTabs, setPreviewTabs] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [warningMessages, setWarningMessages] = useState([]);

  const draftVideoRef = useRef(draftVideo);
  useEffect(() => {
    if (draftVideoRef.current !== draftVideo) {
      setFirebasePath(draftVideo);
      draftVideoRef.current = draftVideo;
    }
  }, [draftVideo]);

  useEffect(() => {
    const draftTab = { id: "draft", label: "Draft", icon: null };
    const hasDraftUpload = formData.some(data => data.uploadTo === 'draft');
    const updatedFormTabs = hasDraftUpload && !formTabs.some(tab => tab.id === "draft")
      ? [draftTab, ...formTabs]
      : formTabs;

    setPreviewTabs(updatedFormTabs);
  }, [formTabs, activePreviewSocialMenu, formData]);

  useEffect(() => {
    setActivePreviewSocialMenu(activeMenuForm);
  }, [activeMenuForm]);

  const handleVideoLoad = useCallback(() => {
    setVideoLoaded(true);
  }, []);

  const toggleModal = useCallback(() => {
    setModalOpen((prev) => !prev);
  }, []);

  const handlePreviewSocialMenuChange = (newTab) => {
    setActivePreviewSocialMenu(newTab);
  };

  const activeFormData = formData.find(data => data.platformId === activePreviewSocialMenu || data.uploadTo === activePreviewSocialMenu);
  const activeSocial = userSocials.find(social => social.platform_id === activePreviewSocialMenu);

  useEffect(() => {
    const errors = [];
    const warnings = [];
    const platformsRequiringMedia = ['instagram', 'youtube', 'tiktok'];
    const platformsWithCheckbox = userSocials.filter(social => platformsRequiringMedia.includes(social.platform) && social.checkbox === true);

    // Check if no userSocials have checkbox set to true
    const noActiveSocials = userSocials.every(social => !social.checkbox);
    if (noActiveSocials) {
      errors.push({
        message: 'Activate at least one social to be able to publish or schedule.',
        icon: MdError,
        color: 'red'
      });
    }

    // Check for platforms missing descriptions, excluding drafts
    const platformsMissingDescription = formData
      .filter(data => !data.description && data.uploadTo !== 'draft')
      .map(data => data.uploadTo);

    if (platformsMissingDescription.length > 0) {
      const platformNames = platformsMissingDescription.join(', ');
      warnings.push({
        message: `The following platforms are missing descriptions: ${platformNames}.`,
        icon: CiWarning,
        color: 'yellow'
      });
    }

    if (getFileType(uploadType) === 'video') {
      const platformsNeedingVideo = platformsWithCheckbox;
      if (platformsNeedingVideo.length > 0 && !firebasePath) {
        const platformNames = platformsNeedingVideo.map(social => social.platform.charAt(0).toUpperCase() + social.platform.slice(1)).join(', ');
        errors.push({
          message: `${platformNames} require a video!`,
          icon: MdError,
          color: 'red'
        });
      }
      
      // TikTok video validation checks
      const tiktokSocial = userSocials.find(social => social.platform === 'tiktok' && social.checkbox === true);
      if (tiktokSocial && firebasePath) {
        // Check file format for TikTok videos
        const fileExtension = firebasePath.split('.').pop().toLowerCase();
        const supportedVideoFormats = ['mp4', 'webm', 'mov'];
        
        if (!supportedVideoFormats.includes(fileExtension)) {
          errors.push({
            message: 'TikTok only supports MP4, WebM, and MOV video formats.',
            icon: MdError,
            color: 'red'
          });
        }
        
        // Add video duration check if video is loaded
        if (videoRef.current && videoLoaded) {
          const duration = videoRef.current.duration;
          if (duration > 600) { // 10 minutes = 600 seconds
            errors.push({
              message: 'TikTok videos must be under 10 minutes in length.',
              icon: MdError,
              color: 'red'
            });
          }
          
          // Check video dimensions if available
          if (videoRef.current.videoWidth && videoRef.current.videoHeight) {
            if (videoRef.current.videoWidth < 360 || videoRef.current.videoHeight < 360) {
              errors.push({
                message: 'TikTok requires videos to be at least 360x360 pixels.',
                icon: MdError,
                color: 'red'
              });
            }
            
            if (videoRef.current.videoWidth > 4096 || videoRef.current.videoHeight > 4096) {
              errors.push({
                message: 'TikTok videos cannot exceed 4096x4096 pixels.',
                icon: MdError,
                color: 'red'
              });
            }
          }
        }
      }
    }

    if (getFileType(uploadType) === 'image') {
      const social = userSocials.find(social => (social.platform === 'instagram' || social.platform === 'tiktok') && social.checkbox === true);
      if (social && !firebasePath) {
        errors.push({
          message: `${social.platform} requires an image!`,
          icon: MdError,
          color: 'red'
        });
      }
      
      // Add platform-specific image validation
      if (firebasePath) {
        const img = new Image();
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          
          // Instagram-specific validation
          const instagramSocial = userSocials.find(social => social.platform === 'instagram' && social.checkbox === true);
          if (instagramSocial) {
            if (aspectRatio < 0.8 || aspectRatio > 1.91) {
              const newError = {
                message: `Instagram image aspect ratio must be between 4:5 (0.8) and 1.91:1 (1.91). Your image has an aspect ratio of ${aspectRatio.toFixed(2)}.`,
                icon: MdError,
                color: 'red'
              };
              
              if (!errors.some(e => e.message === newError.message)) {
                setErrorMessages(prev => [...prev, newError]);
                onError([...errors, newError]);
              }
            }

            if (img.width < 320 || img.width > 1440) {
              const newError = {
                message: `Instagram image width must be between 320px and 1440px. Your image width is ${img.width}px.`,
                icon: MdError,
                color: 'red'
              };
              
              if (!errors.some(e => e.message === newError.message)) {
                setErrorMessages(prev => [...prev, newError]);
                onError([...errors, newError]);
              }
            }
          }

          // TikTok-specific validation
          const tiktokSocial = userSocials.find(social => social.platform === 'tiktok' && social.checkbox === true);
          if (tiktokSocial) {
            if (img.width > 1080 || img.height > 1080) {
              const newError = {
                message: `TikTok images cannot exceed 1080x1080 pixels. Your image is ${img.width}x${img.height} pixels.`,
                icon: MdError,
                color: 'red'
              };
              
              if (!errors.some(e => e.message === newError.message)) {
                setErrorMessages(prev => [...prev, newError]);
                onError([...errors, newError]);
              }
            }

            // Check file format for TikTok images
            const fileExtension = firebasePath.split('.').pop().toLowerCase();
            const supportedImageFormats = ['webp', 'jpeg', 'jpg'];
            
            if (!supportedImageFormats.includes(fileExtension)) {
              const newError = {
                message: 'TikTok only supports WebP and JPEG image formats.',
                icon: MdError,
                color: 'red'
              };
              
              if (!errors.some(e => e.message === newError.message)) {
                setErrorMessages(prev => [...prev, newError]);
                onError([...errors, newError]);
              }
            }
          }
        };
        img.src = firebasePath;
      }
    }

    setErrorMessages(errors);
    setWarningMessages(warnings);
    onError(errors);
  }, [firebasePath, userSocials, formData, uploadType, videoLoaded, videoRef, onError]);

  const renderPost = () => {
    switch (activeSocial?.platform) {
      case 'instagram':
        return <InstagramPost activeSocial={activeSocial} activeFormData={activeFormData} firebasePath={firebasePath} videoRef={videoRef} handleVideoLoad={handleVideoLoad} toggleModal={toggleModal} uploadType={uploadType} />;
      case 'facebook':
        return <FacebookPost activeSocial={activeSocial} activeFormData={activeFormData} firebasePath={firebasePath} videoRef={videoRef} handleVideoLoad={handleVideoLoad} toggleModal={toggleModal} uploadType={uploadType} />;
      case 'tiktok':
        return <TiktokPost activeSocial={activeSocial} activeFormData={activeFormData} firebasePath={firebasePath} videoRef={videoRef} handleVideoLoad={handleVideoLoad} toggleModal={toggleModal} uploadType={uploadType} />;
      case 'x':
        return <TwitterPost activeSocial={activeSocial} activeFormData={activeFormData} firebasePath={firebasePath} videoRef={videoRef} handleVideoLoad={handleVideoLoad} toggleModal={toggleModal} uploadType={uploadType} />;
      default:
        return <DefaultPost activeSocial={activeSocial} activeFormData={activeFormData} firebasePath={firebasePath} videoRef={videoRef} handleVideoLoad={handleVideoLoad} toggleModal={toggleModal} uploadType={uploadType} />;
    }
  };

  return (
    <div className="video-preview h-full flex flex-col">

        <div className={`relative flex flex-col justify-center py-4`}>
          <p className='text-center text-xl font-semibold'>Social Media Post Preview</p>
          {formData.length <= 1 ? (
            <p className='text-center text-sm italic min-h-[2rem]'>
              At the left panel 👈 select your social media platform and start crafting your uploads or continue working on your draft.
            </p>
          ) : (
            <p className='text-center text-sm italic min-h-[2rem]'>&nbsp;</p>
          )}
          <div className='flex flex-col'>
            {(errorMessages.length > 0 || warningMessages.length > 0 || activeFormData?.uploadTo === 'draft') && (
              <GlobalAlert messages={[
                ...errorMessages,
                ...warningMessages,
                ...(activeFormData?.uploadTo === 'draft' ? [{
                  message: 'This is your draft. It wont be uploaded to your socials, only to FrenzyPost.',
                  icon: CiWarning,
                  color: 'yellow'
                }] : [])
              ]} />
            )}
          </div>
          <div className='overflow-x-visible md:overflow-x-auto'>
            <Tabs tabs={previewTabs} onTabChange={handlePreviewSocialMenuChange} activeTab={activePreviewSocialMenu} main={true} />
          </div>
          <div className='mt-2 w-3/4 mx-auto'>
            {renderPost()}
          </div>
        </div>

      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={toggleModal}
        >
          <img
            src={firebasePath}
            alt="enlarged media"
            style={{ maxWidth: '90%', maxHeight: '90%' }}
          />
        </div>
      )}
    </div>
  );
});

export default VideoPreview;

const InstagramPost = React.memo(({ activeSocial, activeFormData, firebasePath, videoRef, handleVideoLoad, toggleModal, uploadType }) => {
  const handleImageError = (event, platform) => {
    event.target.src = `../socials/${platform}-logo.svg`;
  };

  const getVisibilityIcon = () => {
    switch (activeFormData?.privacyStatus) {
      case 'public':
        return <MdPublic size={18} />;
      case 'private':
        return <MdLock size={18} />;
      case 'unlisted':
        return <MdVisibilityOff size={18} />;
      default:
        return <MdPublic size={18} />;
    }
  };

  return (
    <div>
      <div className="--theme-colors shadow-md rounded-lg overflow-hidden">
        <div className="flex items-center p-4 border-b">
          {activeSocial?.logo ? (
            <img
              src={activeSocial.logo}
              alt={activeSocial.username}
              className='w-6 h-6 rounded-full'
              onError={(e) => handleImageError(e, activeSocial.platform)}
            />
          ) : (
            <div className='w-6 h-6 rounded-full bg-purple-300 flex items-center justify-center text-white font-bold'>
              ME
            </div>
          )}
          <div className="ml-3 flex gap-2 items-center w-full">
            <p className="font-semibold text-md">{activeSocial.username}</p>
            <MdVerified color='blue' />
            <GoDotFill size={10} />
            <p className="text-sm text-gray-500 flex-1">3 d</p>
            <IoEllipsisHorizontal />
            {getVisibilityIcon()}
          </div>
        </div>
        <div className="post-content max-w-[300px] sm:w-[300px] mx-auto">
          {firebasePath && (
            <>
              {getFileType(uploadType) === 'video' ? (
                <video
                  ref={videoRef}
                  onLoadedMetadata={handleVideoLoad}
                  controls
                  preload="auto"
                  className={`rounded-md`}
                >
                  <source src={firebasePath} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={firebasePath}
                  className='cursor-pointer'
                  alt="uploaded media"
                  onClick={toggleModal} // Open modal when clicked
                />)}

            </>
          )}
        </div>
        <div className="flex gap-2 p-4 border-t w-full">
          <div className="text-gray-500"><FaHeart /></div>
          <div className="text-gray-500"><FaComment /></div>
          <div className="text-gray-500 flex-1"><LuSend /></div>
          <div className="text-gray-500"><FaRegBookmark /></div>
        </div>
        <div className="p-4">
          <p className='text-sm font-semibold'>156 likes</p>
          <div className='flex gap-2 items-start'>
            <p className='text-sm font-semibold'>{activeSocial.username}</p>
            <MdVerified color='blue' />
            <p className="text-sm">{activeFormData?.description ? styleHashtags(activeFormData.description) : 'Write your caption...'}</p>
          </div>
        </div>
        {activeFormData?.comment && (
          <div className="px-2 py-2 border-t w-full text-[10px]">
            <p className="text-sm font-normal flex items-center gap-2 mb-3">Most relevant replies <FaArrowDown /></p>
            <div className='px-4 flex items-center gap-2'>
              {activeSocial?.logo ? (
                <img
                  src={activeSocial.logo}
                  alt={activeSocial.username}
                  className='w-6 h-6 rounded-full'
                  onError={(e) => handleImageError(e, activeSocial.platform)}
                />
              ) : (
                <div className='p-1 mt-1 rounded-full bg-purple-300 flex items-center justify-center text-white font-semibold'>
                  ME
                </div>
              )}
              <div className='flex flex-col items-start justify-start'>
                <p className='text-[10px] opacity-50 font-semibold text-gray-500'>@{activeSocial?.username || 'me'} - now</p>
                <p className='text-[12px]'>{activeFormData?.comment}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

const FacebookPost = React.memo(({ activeSocial, activeFormData, firebasePath, videoRef, handleVideoLoad, toggleModal, uploadType }) => {
  const handleImageError = (event, platform) => {
    event.target.src = `../socials/${platform}-logo.svg`;
  };

  // Format the date and time
  const formattedDate = new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
  const formattedTime = new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  const getVisibilityIcon = () => {
    switch (activeFormData?.privacyStatus) {
      case 'public':
        return <MdPublic size={18} />;
      case 'private':
        return <MdLock size={18} />;
      case 'unlisted':
        return <MdVisibilityOff size={18} />;
      default:
        return <MdPublic size={18} />;
    }
  };

  return (
    <div className="--theme-colors shadow-md rounded-lg overflow-hidden">
      <div className="flex items-center px-4 py-2">
        {activeSocial?.logo ? (
          <img
            src={activeSocial.logo}
            alt={activeSocial.username}
            className='w-6 h-6 rounded-full'
            onError={(e) => handleImageError(e, activeSocial.platform)}
          />
        ) : (
          <div className='w-6 h-6 rounded-full bg-purple-300 flex items-center justify-center text-white font-bold'>
            ME
          </div>
        )}
        <div className="ml-1 flex flex-col">
          <p className="font-semibold text-md">{activeSocial.username}</p>
          <div className="flex items-center gap-2">
            <span>{getVisibilityIcon()}</span>
            <p className="text-sm text-gray-500">{`${formattedDate} at ${formattedTime}`}</p>
          </div>
        </div>
      </div>
      <div className="px-4 py-2 border-b">
        <p className="text-sm font-semibold">{activeFormData?.title || ''}</p>
        <p className="text-sm">{activeFormData?.description ? styleHashtags(activeFormData.description) : 'Write your caption...'}</p>
      </div>
      <div className="post-content max-w-[300px] sm:w-[300px] mx-auto">
        {firebasePath && (
          <>
            {getFileType(uploadType) === 'video' ? (
              <video
                ref={videoRef}
                onLoadedMetadata={handleVideoLoad}
                controls
                preload="auto"
                className={`rounded-md`}
              >
                <source src={firebasePath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={firebasePath}
                className='cursor-pointer'
                alt="uploaded media"
                onClick={toggleModal} // Open modal when clicked
              />)}

          </>
        )}
      </div>
      <div className="flex items-center p-4 border-t w-full">
        <div className="text-gray-500"><AiFillLike color='blue' /></div>
        <div className="text-gray-500"><FaHeart color='red' /></div>
        <div className="text-gray-500 ml-2 flex-1">6K</div>
        <div className="flex items-center gap-2">
          <p className="text-sm">432 comments</p>
          <p className="text-sm">500 shares</p>
        </div>
      </div>
      <div className="flex justify-around border-t px-1 py-4">
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><AiFillLike size={20} /></div>
          <p className="text-sm">Like</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><FaComment size={20} /></div>
          <p className="text-sm">Comment</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><FaWhatsapp size={20} /></div>
          <p className="text-sm">Send</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><LuSend size={20} /></div>
          <p className="text-sm">Share</p>
        </div>


      </div>
      {activeFormData?.comment && (
        <div className="px-2 py-2 border-t w-full text-[10px]">
          <p className="text-sm font-normal flex items-center gap-2 mb-3">Most relevant replies <FaArrowDown /></p>
          <div className='px-4 flex items-center gap-2'>
            {activeSocial?.logo ? (
              <img
                src={activeSocial.logo}
                alt={activeSocial.username}
                className='w-6 h-6 rounded-full'
                onError={(e) => handleImageError(e, activeSocial.platform)}
              />
            ) : (
              <div className='p-1 mt-1 rounded-full bg-purple-300 flex items-center justify-center text-white font-semibold'>
                ME
              </div>
            )}
            <div className='flex flex-col items-start justify-start'>
              <p className='text-[10px] opacity-50 font-semibold text-gray-500'>@{activeSocial?.username || 'me'} - now</p>
              <p className='text-[12px]'>{activeFormData?.comment}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});



const DefaultPost = React.memo(({ activeSocial, activeFormData, firebasePath, videoRef, handleVideoLoad, toggleModal, uploadType }) => {
  const handleImageError = (event) => {
    event.target.onerror = null;
    event.target.src = '';
    event.target.style.display = 'none';
    event.target.nextSibling.style.display = 'flex';
  };

  const getVisibilityIcon = () => {
    switch (activeFormData?.privacyStatus) {
      case 'public':
        return <MdPublic size={18} />;
      case 'private':
        return <MdLock size={18} />;
      case 'unlisted':
        return <MdVisibilityOff size={18} />;
      default:
        return <MdPublic size={18} />;
    }
  };

  const hasLogo = activeSocial?.logo;

  return (
    <div className="--theme-colors shadow-md rounded-lg overflow-hidden">
      <div className="flex items-center px-4 py-2">
        {hasLogo ? (
          <img
            src={activeSocial.logo}
            alt={activeSocial.username}
            className="w-10 h-10 rounded-full"
            onError={handleImageError}
          />
        ) : (
          <div className="w-10 h-10 rounded-full bg-purple-300 flex items-center justify-center text-white font-bold">
            D
          </div>
        )}
        <div className="ml-3 flex flex-col">
          <p className="font-semibold text-md">{activeSocial?.username || 'Draft'}</p>
          <div className="flex items-center gap-2">
            <div className="text-gray-500">{getVisibilityIcon()}</div>
            <p className="text-[11px] italic text-gray-500">Now</p>
          </div>
        </div>
      </div>
      <div className="px-4 py-2 border-b">
        <p className="text-sm font-semibold">{activeFormData?.title || ''}</p>
        <p className="text-sm">{activeFormData?.description ? styleHashtags(activeFormData.description) : 'Write your caption...'}</p>
      </div>
      <div className="post-content max-w-[300px] sm:w-[300px] mx-auto">
        {firebasePath && (
          <>
            {getFileType(uploadType) === 'video' ? (
              <video
                ref={videoRef}
                onLoadedMetadata={handleVideoLoad}
                controls
                preload="auto"
                className={`rounded-md`}
              >
                <source src={firebasePath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={firebasePath}
                className='cursor-pointer'
                alt="uploaded media"
                onClick={toggleModal} // Open modal when clicked
              />
            )}
          </>
        )}
      </div>
      <div className="flex gap-2 p-4 border-t w-full">
        <div className="text-red-500"><FaHeart /></div>
        <div className="text-gray-500"><FaComment /></div>
        <div className="text-gray-500 flex-1"><LuSend /></div>
      </div>
      {activeFormData?.comment && (
        <div className="px-2 py-2 border-t w-full text-[10px]">
          <p className="text-sm font-normal flex items-center gap-2 mb-3">Most relevant replies <FaArrowDown /></p>
          <div className='px-4 flex items-center gap-2'>
            {activeSocial?.logo ? (
              <img
                src={activeSocial.logo}
                alt={activeSocial.username}
                className='w-6 h-6 rounded-full'
                onError={(e) => handleImageError(e, activeSocial.platform)}
              />
            ) : (
              <div className='p-1 mt-1 rounded-full bg-purple-300 flex items-center justify-center text-white font-semibold'>
                ME
              </div>
            )}
            <div className='flex flex-col items-start justify-start'>
              <p className='text-[10px] opacity-50 font-semibold text-gray-500'>@{activeSocial?.username || 'me'} - now</p>
              <p className='text-[12px]'>{activeFormData?.comment}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});


const TiktokPost = React.memo(({ activeSocial, activeFormData, firebasePath, videoRef, handleVideoLoad, toggleModal, uploadType }) => {
  const handleImageError = (event) => {
    event.target.onerror = null;
    event.target.src = '';
    event.target.style.display = 'none';
    event.target.nextSibling.style.display = 'flex';
  };

  const getVisibilityIcon = () => {
    switch (activeFormData?.privacyStatus) {
      case 'public':
        return <MdPublic size={18} />;
      case 'private':
        return <MdLock size={18} />;
      case 'unlisted':
        return <MdVisibilityOff size={18} />;
      default:
        return <MdPublic size={18} />;
    }
  };

  const hasLogo = activeSocial?.logo;

  return (
    <div className="--theme-colors shadow-md rounded-lg overflow-hidden">
      <div className="flex items-center px-4 py-2">
        {hasLogo ? (
          <img
            src={activeSocial.logo}
            alt={activeSocial.username}
            className="w-10 h-10 rounded-full"
            onError={handleImageError}
          />
        ) : (
          <div className="w-10 h-10 rounded-full bg-purple-300 flex items-center justify-center text-white font-bold">
            D
          </div>
        )}
        <div className="ml-3 flex flex-col">
          <p className="font-semibold text-md">{activeSocial?.username || 'Draft'}</p>
          <div className="flex items-center gap-2">
            <div className="text-gray-500">{getVisibilityIcon()}</div>
            <p className="text-[11px] italic text-gray-500">Now</p>
          </div>
        </div>
      </div>
      <div className="px-4 py-2 border-b">
        <p className="text-sm font-semibold">{activeFormData?.title || ''}</p>
        <p className="text-sm">{activeFormData?.description ? styleHashtags(activeFormData.description) : 'Write your caption...'}</p>
      </div>
      <div className="relative max-w-[300px] !w-[90%] sm:w-[300px] mx-auto">
        {firebasePath && (
          <>
            {getFileType(uploadType) === 'video' ? (
              <video
                ref={videoRef}
                onLoadedMetadata={handleVideoLoad}
                controls
                preload="auto"
                className="w-full h-auto rounded-md"
              >
                <source src={firebasePath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={firebasePath}
                className='cursor-pointer'
                alt="uploaded media"
                onClick={toggleModal} // Open modal when clicked
              />
            )}
            {/*
            <img
              src="../socials/tiktok.svg"
              alt="Overlay SVG"
              className="pointer-events-none absolute top-0 left-0 w-full h-full opacity-90"
            />
            */}
          </>
        )}
      </div>
      {activeFormData?.comment && (
        <div className="px-2 py-2 border-t w-full text-[10px]">
          <p className="text-sm font-normal flex items-center gap-2 mb-3">Most relevant replies <FaArrowDown /></p>
          <div className='px-4 flex items-center gap-2'>
            {activeSocial?.logo ? (
              <img
                src={activeSocial.logo}
                alt={activeSocial.username}
                className='w-6 h-6 rounded-full'
                onError={(e) => handleImageError(e)}
              />
            ) : (
              <div className='p-1 mt-1 rounded-full bg-purple-300 flex items-center justify-center text-white font-semibold'>
                ME
              </div>
            )}
            <div className='flex flex-col items-start justify-start'>
              <p className='text-[10px] opacity-50 font-semibold text-gray-500'>@{activeSocial?.username || 'me'} - now</p>
              <p className='text-[12px]'>{activeFormData?.comment}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});



const TwitterPost = React.memo(({ activeSocial, activeFormData, firebasePath, videoRef, handleVideoLoad, toggleModal, uploadType }) => {
  const handleImageError = (event) => {
    event.target.onerror = null;
    event.target.src = '';
    event.target.style.display = 'none';
    event.target.nextSibling.style.display = 'flex';
  };

  const getVisibilityIcon = () => {
    switch (activeFormData?.privacyStatus) {
      case 'public':
        return <MdPublic size={18} />;
      case 'private':
        return <MdLock size={18} />;
      case 'unlisted':
        return <MdVisibilityOff size={18} />;
      default:
        return <MdPublic size={18} />;
    }
  };

  return (
    <div className="--theme-colors shadow-md rounded-lg overflow-hidden">
      <div className="flex items-center px-4 py-2 w-full">
        <div className="flex items-center p-4 w-full">
          <img
            src={activeSocial?.logo || ``}
            alt={activeSocial?.username}
            className="w-10 h-10 rounded-full"
            onError={(e) => handleImageError(e, activeSocial.platform)}
          />
          <div className="ml-3 flex gap-2 items-center w-full">
            <p className="font-semibold text-md">{activeSocial.username}</p>
            <MdVerified color='blue' />
            <GoDotFill size={10} />
            <p className="text-sm text-gray-500 flex-1">Now</p>
            {getVisibilityIcon()}
            <IoEllipsisHorizontal />
          </div>
        </div>
      </div>
      <div className="px-4 py-2 border-b">
        <p className="text-sm font-semibold">{activeFormData?.title || ''}</p>
        <p className="text-sm">{activeFormData?.description ? styleHashtags(activeFormData.description) : 'Write your caption...'}</p>
      </div>
      <div className="relative max-w-[300px] !w-[90%] sm:w-[300px] mx-auto">
        {firebasePath && (
          <>
            {getFileType(uploadType) === 'video' ? (
              <video
                ref={videoRef}
                onLoadedMetadata={handleVideoLoad}
                controls
                preload="auto"
                className={`rounded-md`}
              >
                <source src={firebasePath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={firebasePath}
                className='cursor-pointer'
                alt="uploaded media"
                onClick={toggleModal} // Open modal when clicked
              />
            )}
          </>
        )}
      </div>
      <div className="flex justify-around border-t px-1 py-4">
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><FaComment size={20} /></div>
          <p className="text-sm">3K</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><BiRepost size={20} /></div>
          <p className="text-sm">4.1K</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><FaHeart size={20} color='red' /></div>
          <p className="text-sm">54K</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-gray-500"><BiSolidBarChartAlt2 size={20} /></div>
          <p className="text-sm">3M</p>
        </div>

        <div className="flex items-center gap-2">
          <div className="text-gray-500"><FaBookmark size={10} /></div>
          <FaUpload size={10} />
        </div>

      </div>
      {activeFormData?.comment && (
        <div className="px-2 py-2 border-t w-full text-[10px]">
          <p className="text-sm font-normal flex items-center gap-2 mb-3">Most relevant replies <FaArrowDown /></p>
          <div className='px-4 flex items-center gap-2'>
            {activeSocial?.logo ? (
              <img
                src={activeSocial.logo}
                alt={activeSocial.username}
                className='w-6 h-6 rounded-full'
                onError={(e) => handleImageError(e)}
              />
            ) : (
              <div className='p-1 mt-1 rounded-full bg-purple-300 flex items-center justify-center text-white font-semibold'>
                ME
              </div>
            )}
            <div className='flex flex-col items-start justify-start'>
              <p className='text-[10px] opacity-50 font-semibold text-gray-500'>@{activeSocial?.username || 'me'} - now</p>
              <p className='text-[12px]'>{activeFormData?.comment}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});


const styleHashtags = (text) => {
  const hashtagRegex = /#(\w+)/g;
  return text.split(hashtagRegex).map((part, index) =>
    index % 2 === 1 ? <span key={index} className="text-blue-500">#{part}</span> : part
  );
};

// Enhanced GlobalAlert component to handle multiple messages with collapsible functionality
const GlobalAlert = React.memo(({ messages }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const errorCount = messages.filter(msg => msg.color === 'red').length;
  const warningCount = messages.filter(msg => msg.color === 'yellow').length;

  const getBackgroundColor = (color) => {
    switch (color) {
      case 'red':
        return '#FEE2E2'; // Equivalent to bg-red-100
      case 'green':
        return '#D1FAE5'; // Equivalent to bg-green-100
      case 'blue':
        return '#DBEAFE'; // Equivalent to bg-blue-100
      case 'yellow':
        return '#FEF3C7'; // Equivalent to bg-yellow-100
      default:
        return '#FFFFFF'; // Default to white if color is not recognized
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="mb-4">
      <div className="flex items-center mb-2">
        {errorCount > 0 && (
          <div className="flex items-center !bg-red-100 !text-red-500 px-3 py-1 rounded-l-md mr-2">
            <MdError className="mr-1" />
            <span>{errorCount}</span>
          </div>
        )}
        {warningCount > 0 && (
          <div className="flex items-center !bg-yellow-100 !text-yellow-500 px-3 py-1 rounded-r-md">
            <MdWarning className="mr-1" />
            <span>{warningCount}</span>
          </div>
        )}
        {(errorCount > 0 || warningCount > 0) && (
          <div onClick={toggleCollapse} className="text-blue-500 border-b border-blue-500 px-2 py-1 cursor-pointer ml-2 font-semibold" style={{ userSelect: 'none' }}>
            {isCollapsed ? 'Show' : 'Hide'}
          </div>
        )}
      </div>
      {!isCollapsed && (errorCount > 0 || warningCount > 0) && (
        <div>
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`flex items-center gap-3 !text-black border border-${msg.color}-200 text-${msg.color}-600 p-4 text-[14px] rounded mb-2`}
              style={{ backgroundColor: getBackgroundColor(msg.color) }}
            >
              <msg.icon size={30} />
              <span className='!text-gray-500 font-semibold'>{msg.message}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});
