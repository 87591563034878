import React from 'react';
import { useWorkspace } from '../../../context/WorkspaceContext';
import { Link } from 'react-router-dom';

const TopVerticalBar = () => {
    const { workspaces, loading, error, activeWorkspace, changeActiveWorkspace } = useWorkspace();
    const handleWorkspaceClick = (workspace) => {
        changeActiveWorkspace(workspace);
    };

    const renderSkeleton = () => (
        <div className="flex items-center flex-col relative cursor-pointer">
            <div className="inline-block h-10 w-10 rounded-full bg-gray-500 opacity-50 animate-pulse"></div>
            <span className="bg-gray-500 h-4 w-20 mt-2 opacity-50 rounded-md animate-pulse"></span>
        </div>
    );

    return (
        <nav className="border-b px-4 py-2 shadow-lg sticky top-0 z-10 vertical-menu">
            <div className="container mx-auto flex flex-col md:flex-row gap-4 md:gap-0 items-center justify-between">
                <div className="flex items-center">
                    <Link to={`/dashboard`}>
                        <h1 className="text-2xl font-bold nav-link !p-0 !m-0">
                            {activeWorkspace ? activeWorkspace.name : 'Dashboard'}
                        </h1>
                    </Link>
                </div>
                <div className="flex items-center gap-10">
                    {loading ? (
                        renderSkeleton()
                    ) : error ? (
                        <span className="text-red-500">{error}</span>
                    ) : workspaces.length > 0 && (
                        workspaces.map((workspace) => (
                            <div
                                key={workspace.id}
                                onClick={() => handleWorkspaceClick(workspace)}
                                className={`hover:text-gray-300 flex items-center flex-col relative cursor-pointer ${activeWorkspace && activeWorkspace.id === workspace.id ? 'text-blue-500' : 'opacity-60'}`}
                            >
                                    <img
                                        src={workspace.image || `${process.env.PUBLIC_URL}/random/no-image.png`}
                                        alt={workspace.name}
                                        className={`inline-block h-10 w-10 rounded-full ${activeWorkspace && activeWorkspace.id === workspace.id ? 'border-2 border-purple-500' : ''}`}
                                        onError={(e) => {
                                            e.target.src = `${process.env.PUBLIC_URL}/random/no-image.png`;
                                        }}
                                    />
                                <span className={`${activeWorkspace && activeWorkspace.id === workspace.id ? '!text-blue-500' : '!text-gray-500'} max-w-fit truncate max-w-[95px] text-center`}>{workspace.name}</span>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </nav>
    );
};

export default TopVerticalBar;
