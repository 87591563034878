const DisplayError = () => {


    return (
        <div className="flex flex-col items-center justify-center">
            <img src={`${process.env.PUBLIC_URL}/random/no-files.png`} alt="No Files Uploaded" className="max-w-[100%] max-h-[100%]" />
            <p className="text-sm italic text-center text-gray-500">This folder is empty...</p>
        </div>
    );
};

export default DisplayError;
