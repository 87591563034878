import { ImCheckmark } from 'react-icons/im';
import { motion } from 'framer-motion';

const Checkboxbtn = ({ name, platform, logo, status, active }) => {
  const handleImageError = (event, platform) => {
    event.target.src = `../socials/${platform}-logo.svg`;
  };

  const randomDelay = Math.random();

  return (
    <div className='flex flex-col items-center w-full cursor-pointer'>
      <div className={`relative w-[40px] mx-auto border-2 rounded-full p-1 ${active ? 'border-purple-500' : 'border-gray-300'}`}>
        {status && <ImCheckmark size={20} color='green' className='absolute top-[-5px] right-[-5px]' />}
        <img
          src={!platform ? `../no-thumbnail.png` : `../socials/${platform}-logo.svg`}
          alt="Social Media Logo"
          className="absolute w-[20px] bottom-2 right-0 z-10 bg-white p-1 rounded-full"
        />

        <motion.div
          animate={
            active
              ? { y: 0, opacity: 1 }
              : { y: [0, -20, 0], opacity: [1, 0.3, 1] }
          }
          transition={
            active
              ? { duration: 0 }
              : {
                duration: 1,
                delay: randomDelay,
                ease: 'easeInOut',
                repeat: 5,
                repeatType: 'loop'
              }
          }
        >
          <img
            src={!logo ? '' : `${logo}`}
            onError={(e) => handleImageError(e, platform)}
            alt="Creator Avatar"
            className="z-99 w-full rounded-full"
          />
        </motion.div>
      </div>

      <div className="text-center text-[12px]">
        {name}
      </div>
    </div>
  );
};

export default Checkboxbtn;
