import React, { useState, useEffect, useRef } from 'react';

const Slider = ({
    value = 0,
    onChange,
    label,
    min = 0,
    max = 10,
    step = 1,
    labelColor = 'text-gray-500',
}) => {
    const [percentage, setPercentage] = useState(0);
    const sliderRef = useRef(null);

    useEffect(() => {
        const perc = ((value - min) / (max - min)) * 100;
        setPercentage(perc);
    }, [value, min, max]);

    const handleChange = (e) => {
        if (onChange) {
            onChange(Number(e.target.value));
        }
    };

    return (
        <div className="w-full max-w-md mx-auto p-6">
            {label && (
                <label htmlFor={`slider-${label}`} className={`block text-lg font-semibold mb-4 ${labelColor}`}>
                    {label}
                </label>
            )}
            <div className="relative">
                <input
                    type="range"
                    id={`slider-${label}`}
                    name={`slider-${label}`}
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={handleChange}
                    ref={sliderRef}
                    className="w-full h-4 bg-transparent focus:outline-none"
                    style={{
                        background: `linear-gradient(to right, #3b82f6 ${percentage}%, #d1d5db ${percentage}%)`,
                        borderRadius: '999px',
                        appearance: 'none',
                    }}
                    aria-valuemin={min}
                    aria-valuemax={max}
                    aria-valuenow={value}
                    aria-label={label || "Slider"}
                />
                <style jsx>{`
          input[type='range']::-webkit-slider-thumb {
            appearance: none;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #3b82f6;
            cursor: pointer;
            transition: background 0.3s ease, transform 0.2s ease;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
          }
          input[type='range']::-webkit-slider-thumb:hover {
            background: #2563eb;
            transform: scale(1.1);
          }
          input[type='range']::-moz-range-thumb {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #3b82f6;
            cursor: pointer;
            transition: background 0.3s ease, transform 0.2s ease;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
          }
          input[type='range']::-moz-range-thumb:hover {
            background: #2563eb;
            transform: scale(1.1);
          }
          input[type='range']::-ms-thumb {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #3b82f6;
            cursor: pointer;
            transition: background 0.3s ease, transform 0.2s ease;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
          }
          input[type='range']::-ms-thumb:hover {
            background: #2563eb;
            transform: scale(1.1);
          }
          input[type='range']::-ms-track {
            width: 100%;
            cursor: pointer;
            background: transparent;
            border-color: transparent;
            color: transparent;
          }
        `}</style>
            </div>

            <div
                className="bg-blue-500 text-white text-sm font-semibold px-3 py-1 rounded-md shadow-md inline-block"
                style={{
                    position: 'relative',
                    left: `${percentage}%`,
                    transform: 'translateX(-50%)',
                }}
            >
                {value}
            </div>

            <div className={`flex justify-between text-sm mt-2 ${labelColor}`}>
                <span>{min}</span>
                <span>{max}</span>
            </div>
        </div>
    );
};

export default Slider;