import { useState, useEffect, useRef } from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { MdOutlineEmojiEmotions, MdPublic, MdLock, MdVisibilityOff } from "react-icons/md";
import { GoMention } from "react-icons/go";
import { BsThreeDotsVertical } from "react-icons/bs";
import axios from "axios";
import { FaHashtag, FaRegClosedCaptioning } from "react-icons/fa";
import useLocalStorage from "use-local-storage";
import { useWorkspace } from '../../context/WorkspaceContext';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Textarea = ({ onDescriptionChange, initialDescription, placeholder, formData, handlePrivacyStatusChange, showThreeDots = true, showPrivacy = true, rows = 4, maxLength = null }) => {
    const [description, setDescription] = useState(initialDescription);
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuItems, setMenuItems] = useState(null);
    const [activeWorkspace] = useLocalStorage("activeWorkspace");
    const [wordCount, setWordCount] = useState(0);
    const menuContainerRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [visibility, setVisibility] = useState(formData.privacyStatus || 'public');
    const [isVisibilityDropdownOpen, setIsVisibilityDropdownOpen] = useState(false);
    const { idToken } = useWorkspace();
    const handleInputChange = (e) => {
        const value = e.target.value;
        setDescription(value);
        setWordCount(value.length);
        onDescriptionChange(value);
    };

    useEffect(() => {
        setDescription(initialDescription);
    }, [initialDescription]);

    const handleInputChangeEmoji = (e) => {
        const updatedDescription = `${description} ${e.native}`;
        setDescription(updatedDescription);
        onDescriptionChange(updatedDescription);
        setIsPickerVisible(false);
    };

    const handleMention = () => {
        const updatedDescription = `${description} @`;
        setDescription(updatedDescription);
        onDescriptionChange(updatedDescription);
    };

    const handleThreeDots = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + "/api/auth/user/templates", {
                params: { workspaceId: activeWorkspace.id },
                headers: { Authorization: `Bearer ${idToken}` }
            });
            setMenuItems(response.data);
        } catch (error) {
            console.error(error);
        }
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuItemClick = (id) => {
        const clickedTemplate = menuItems.find((template) => template._id === id);
        if (clickedTemplate) {
            const text = clickedTemplate.info;
            const newDescription = `${description} ${text}`;
            setDescription(newDescription);
            onDescriptionChange(newDescription);
        }
        setIsMenuOpen(false);
    };

    // Close menu on outside click
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                menuContainerRef.current &&
                !menuContainerRef.current.contains(event.target)
            ) {
                setIsMenuOpen(false);
            }
            if (
                isVisibilityDropdownOpen &&
                !event.target.closest('.privacy-dropdown')
            ) {
                setIsVisibilityDropdownOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isVisibilityDropdownOpen]);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleVisibilityChange = (newVisibility) => {
        setVisibility(newVisibility);
        handlePrivacyStatusChange('privacyStatus', newVisibility);
        setIsVisibilityDropdownOpen(false);
    };

    const getVisibilityIcon = () => {
        switch (visibility) {
            case 'public':
                return <MdPublic size={18} className="text-blue-500" />;
            case 'private':
                return <MdLock size={18} />;
            case 'unlisted':
                return <MdVisibilityOff size={18} className="text-blue-500" />;
            default:
                return <MdPublic size={18} />;
        }
    };

    const getMaxLengthColor = () => {
        if (maxLength && wordCount >= maxLength) {
            return 'text-red-500';
        } else if (maxLength && wordCount >= maxLength * 0.8) {
            return 'text-yellow-500';
        }
        return 'text-gray-500';
    };

    return (
        <div className={`w-full border --theme-colors rounded-lg shadow-sm text-sm mb-4 ${isFocused ? 'bg-white' : 'bg-gray-100'}`}>
            <textarea
                className={`w-full p-3 --theme-colors text-sm appearance-none outline-none border-none resize-none placeholder-gray-400 ${isFocused ? 'bg-white' : '!bg-gray-100'}`}
                rows={rows}
                placeholder={`${placeholder}`}
                onChange={handleInputChange}
                value={description}
                onFocus={handleFocus}
                onBlur={handleBlur}
                maxLength={maxLength}
            />

            <div className="flex items-center gap-2 relative w-full">
                {showThreeDots && (
                    <div
                        className="cursor-pointer rounded hover:bg-gray-200 px-2 py-1.5 text-sm font-medium text-gray-700 hover:text-blue-500"
                        onClick={handleThreeDots}
                    >
                        <BsThreeDotsVertical size={20} />
                    </div>
                )}

                <div
                    className="cursor-pointer rounded hover:bg-gray-200 px-2 py-1.5 text-sm font-medium text-gray-700 hover:text-blue-500"
                    onClick={() => setIsPickerVisible(!isPickerVisible)}
                >
                    <MdOutlineEmojiEmotions size={20} className="text-yellow-600" />
                </div>

                <div
                    className="cursor-pointer rounded hover:bg-gray-200 px-2 py-1.5 text-sm font-medium text-gray-700 hover:text-blue-500"
                    onClick={handleMention}
                >
                    <GoMention size={20} />
                </div>

                {showPrivacy && (
                    <div
                        className="cursor-pointer rounded hover:bg-gray-200 px-2 py-1.5 text-sm font-medium text-gray-700 hover:text-blue-500"
                        onClick={() => setIsVisibilityDropdownOpen(!isVisibilityDropdownOpen)}
                    >
                        <span className="flex items-center gap-1 text-[11px]">{getVisibilityIcon()}</span>
                    </div>
                )}

                {isVisibilityDropdownOpen && (
                    <div className="privacy-dropdown absolute top-10 bg-white z-10 shadow-md rounded-md p-4 space-y-3">
                        <div className="text-gray-700 font-medium mb-2">Who can see your post?</div>

                        <div
                            className={`flex items-center space-x-3 rounded-lg border p-4 cursor-pointer ${visibility === 'public' ? 'bg-gray-200' : ''}`}
                            onClick={() => handleVisibilityChange('public')}
                        >
                            <MdPublic className="h-5 w-5 text-primary" />
                            <span className="flex-grow cursor-pointer">Public</span>
                        </div>

                        <div
                            className={`flex items-center space-x-3 rounded-lg border p-4 cursor-pointer ${visibility === 'private' ? 'bg-gray-200' : ''}`}
                            onClick={() => handleVisibilityChange('private')}
                        >
                            <MdLock className="h-5 w-5 text-primary" />
                            <span className="flex-grow cursor-pointer">Private</span>
                        </div>

                        {formData.uploadTo === 'youtube' && (
                            <div
                                className={`flex items-center space-x-3 rounded-lg border p-4 cursor-pointer ${visibility === 'unlisted' ? 'bg-gray-200' : ''}`}
                                onClick={() => handleVisibilityChange('unlisted')}
                            >
                                <MdVisibilityOff className="h-5 w-5 text-primary" />
                                <span className="flex-grow cursor-pointer">Unlisted</span>
                            </div>
                        )}
                    </div>
                )}

                <div className="flex-1 cursor-default" />

                <div className={`text-right justify-end self-end text-sm pr-3 pb-2 ${getMaxLengthColor()}`}>
                    {wordCount}
                    {maxLength && (
                        <span> / {maxLength}</span>
                    )}
                </div>

                {isMenuOpen && (
                    <div
                        ref={menuContainerRef}
                        className="menu absolute top-0 left-5 w-[150px] shadow-md rounded-md z-10 py-2 --theme-colors"
                    >
                        {menuItems?.reduce(
                            (acc, item) => {
                                if (item.template_type === "hashtag") {
                                    if (!acc.hasHashtag) {
                                        acc.hasHashtag = true;
                                        acc.elements.push(
                                            <div
                                                key="hashtag-title"
                                                className="flex items-center gap-2 text-md font-bold mb-1 cursor-default pt-2 border-t border-gray-400 w-[90%] mx-auto"
                                            >
                                                <FaHashtag size={12} />
                                                Hashtag
                                            </div>
                                        );
                                    }
                                } else if (item.template_type === "caption") {
                                    if (!acc.hasCaption) {
                                        acc.hasCaption = true;
                                        acc.elements.push(
                                            <div
                                                key="caption-title"
                                                className="flex items-center gap-2 text-md font-bold mb-1 cursor-default pt-2 border-t border-gray-400 w-[90%] mx-auto"
                                            >
                                                <FaRegClosedCaptioning size={12} />
                                                Caption
                                            </div>
                                        );
                                    }
                                }

                                acc.elements.push(
                                    <div
                                        key={item._id}
                                        className="cursor-pointer text-gray-800 hover:text-blue-500 py-2 w-[90%] mx-auto p-1 hover:bg-gray-100"
                                        onClick={() => handleMenuItemClick(item._id)}
                                    >
                                        {item.name}
                                    </div>
                                );

                                return acc;
                            },
                            { hasHashtag: false, hasCaption: false, elements: [] }
                        )?.elements}
                    </div>
                )}

                {isPickerVisible && (
                    <div className="absolute top-10 bg-white z-10">
                        <Picker
                            data={data}
                            previewPosition="none"
                            onEmojiSelect={handleInputChangeEmoji}
                            theme="auto"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Textarea;
