import React, { useState } from 'react';
import axios from 'axios';
import { useWorkspace } from '../../../context/WorkspaceContext.js';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const XLoginButton = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { idToken } = useWorkspace();

    const handleAuthClick = () => {
        if (!idToken) {
            console.error('idToken is not set');
            return;
        }

        setIsLoading(true);
        axios.get(BACKEND_API_URL + '/api/x/oauth', {
            headers: { Authorization: `Bearer ${idToken}` }
        })
            .then(response => {
                localStorage.setItem('codeVerifier', response.data.codeVerifier);
                localStorage.setItem('state', response.data.state);
                window.open(response.data.url, '_blank');
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    };

    return (
        <button
            className="bg-black hover:bg-gray-800 h-[70px] p-4 rounded-lg flex items-center w-full justify-between"
            onClick={handleAuthClick}
            disabled={isLoading}
            data-scope="public_profile,email,instagram_basic"
        >
            {isLoading ? 'Loading...' : (
                <>
                    <div className="mr-3 w-12 h-12 bg-white rounded-full flex items-center justify-center">
                        <img
                            src={'../socials/x-logo.svg'}
                            alt="X Logo"
                            className="w-8 h-8"
                        />
                    </div>
                    <p className="text-white font-bold">X (twitter)</p>
                </>
            )}
            <div></div>
        </button>
    );
};

export default XLoginButton;