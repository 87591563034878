import React, { useState, useEffect, useRef } from 'react';
import './Modal.css';
import 'react-phone-number-input/style.css'; // Import CSS styles for react-phone-number-input
import axios from 'axios'; // Import Axios
import { FaTimes } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import { addMonths } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";

//buttons
import TikTokLoginButton from '../Buttons/TikTok/TikTokLoginButton';
import InstagramLoginButton from '../Buttons/Facebook/InstagramLoginButton';
import FacebookLoginButton from '../Buttons/Facebook/FacebookLoginButton';
import XLoginButton from '../Buttons/X/XLoginButton';
import YoutubeLoginButton from '../Buttons/Google/GoogleLoginButton';
import { useNavigate } from 'react-router-dom';
import { render } from '@react-email/components';
import DeleteAccountAdmin from '../../emails/DeleteAccountAdmin.tsx';
import DeleteAccountUser from '../../emails/DeleteAccountUser.tsx';
import Upload from '../../pages/Protected/Content/Upload/Upload.js';

import SendEmail from '../Tools/SendEmail.js';
import useLocalStorage from 'use-local-storage';
import { LoadingIndicator } from '../Tools/LoadingIndicator.js';
import { useWorkspace } from '../../context/WorkspaceContext.js';
import { getAuth, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { authenticateInstagram } from '../Buttons/Facebook/InstagramLoginButton';
import { Button } from '../../ui/button.js';
import Library from '../../pages/Protected/Library/Library.js';
import ThreadsLoginButton from '../Buttons/Threads/ThreadsLoginButton.js';
import ManageUpload from '../ManageUpload/ManageUpload.js';
import Todo from '../ToDo/Todo.js';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const Modal = ({ open, closeModal, type, otherInfo }) => {
  const { idToken } = useWorkspace();
  const modalRef = useRef(null);

  // Close modal on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const modalOverlay = document.querySelector('.modal-overlay');
      if (open && event.target === modalOverlay && type !== 'ScheduledModal') {
        closeModal();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [open, closeModal, type]);

  // Close modal on Escape key press
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape' && open) {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [open, closeModal, type]);


  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 modal-overlay ${open ? '' : 'hidden'}`}>
      <div className={`--theme-colors rounded-lg overflow-hidden shadow-xl transform transition-all !w-min`} ref={modalRef}>

        {type !== 'ScheduledModal' && (
          <div className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={() => closeModal(false)}>
            <FaTimes size={16} />
          </div>
        )}
        {type === 'PasswordModal' && <PasswordModal closeModal={closeModal} email={otherInfo} idToken={idToken} />}
        {type === 'VerifyEmailModal' && <VerifyEmailModal closeModal={closeModal} name={otherInfo} idToken={idToken} />}
        {type === 'TemplatesModal' && <TemplatesModal closeModal={closeModal} type={otherInfo} idToken={idToken} />}
        {type === 'ConnectSocialsModal' && <ConnectSocialsModal closeModal={closeModal} idToken={idToken} />}
        {type === 'VideoUploaderModal' && <VideoUploaderModal closeModal={closeModal} draft={otherInfo} idToken={idToken} />}
        {type === 'ScheduledModal' && <ScheduledModal date={otherInfo} closeModal={closeModal} idToken={idToken} />}
        {type === 'VerifyDelete' && <VerifyDelete closeModal={closeModal} drafts={otherInfo} idToken={idToken} />}
        {type === 'ConfirmHideGuide' && <ConfirmHideGuide closeModal={closeModal} idToken={idToken} />}
        {type === 'AddTeamMemberModal' && <AddTeamMemberModal closeModal={closeModal} workspace={otherInfo} idToken={idToken} />}
        {type === 'DeleteAccountModal' && <DeleteAccountModal closeModal={closeModal} userInfo={otherInfo} idToken={idToken} />}
        {type === 'ConfirmDeleteWorkspace' && <ConfirmDeleteWorkspace closeModal={closeModal} workspace={otherInfo} idToken={idToken} />}
        {type === 'CreateWorkspaceModal' && <CreateWorkspaceModal closeModal={closeModal} otherInfo={otherInfo} idToken={idToken} />}
        {type === 'LibraryModal' && <LibraryModal closeModal={closeModal} />}
        {type === 'ViewUpload' && <ManageUploadModal closeModal={closeModal} upload={otherInfo} idToken={idToken} />}
        {type === 'TodoModal' && <TodoModal closeModal={closeModal} />}
      </div>
    </div>
  );
}

const PasswordModal = ({ closeModal, email, idToken }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailInput, setEmailInput] = useState(email ? email : '');
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true); // Add isValidEmail state
  const [feedback, setFeedback] = useState('');

  const sendResetPasswordEmail = async () => {
    setIsLoading(true);
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, emailInput);
      setFeedback('A password reset link has been sent to your email!');
    } catch (error) {
      setFeedback('Error: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const isValid = validateEmail(emailInput);
    setIsConfirmButtonDisabled(!isValid || isLoading);
  }, [emailInput, isLoading]);


  return (
    <>
      <div className="--theme-colors px-4 py-5 sm:p-6 modal-wrapper items-center justify-center flex-column w-[500px]">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4 text-center">
          RESET YOUR PASSWORD
        </h2>
        <p className="text-gray-700 mb-4 text-center">
          To enhance your account security, we will send you an email containing a verification code to reset your password.
        </p>
        <div className='text-center'>
          <input
            type="email"
            value={emailInput}
            onChange={(e) => { setEmailInput(e.target.value); }}
            placeholder="Enter your email"
            className="bg-gray-100 rounded-full py-2 px-4 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-400 mr-5"
          />
          {isLoading ? (
            <div className="loading-animation"></div>
          ) : (
            <button
              onClick={sendResetPasswordEmail}
              className={`bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 ${isConfirmButtonDisabled ? 'bg-gray-300 cursor-not-allowed' : ''}`}
              disabled={isConfirmButtonDisabled}
            >
              Confirm
            </button>
          )}
          {feedback && <p className={feedback.includes('Error') ? '--error-message' : '--success-message'}>{feedback}</p>}
        </div>
      </div>
    </>
  )
}

const VerifyEmailModal = ({ closeModal, name, idToken }) => {
  const [responseData, setResponsData] = useState(null);
  const [error, setError] = useState(false);

  const sendVerifyEmail = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      setResponsData("No user is signed in.");
      setError(true);
      return;
    }

    try {
      await sendEmailVerification(user);
      setResponsData("Verification email sent successfully.");
      setError(false);
    } catch (error) {
      setResponsData(error.message);
      setError(true);
    }
  };

  return (
    <>
      <div className="px-4 py-5 sm:p-6 modal-wrapper items-center justify-center flex-column text-center w-[500px]">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">
          Email Verification
        </h2>
        <p className="text-gray-700 mb-4">
          Thank you for signing up! To complete your registration, please click the button below to verify your email address.
        </p>
        <p className="text-gray-700 mb-6 italic">
          Note: This link will expire in 30 minutes.
        </p>
        {responseData ? (
          <button onClick={closeModal} className="bg-red-400 hover:bg-blue-400 text-white font-semibold py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
            Close
          </button>
        ) : (
          <button onClick={sendVerifyEmail} className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
            Confirm
          </button>
        )}
        {responseData && <p className={error ? '--error-message' : '--success-message'}>{responseData}</p>}
      </div>
    </>
  );
}

const TemplatesModal = ({ closeModal, type, idToken }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [count, setCount] = useState(0);
  const [activeWorkspace] = useLocalStorage("activeWorkspace");


  useEffect(() => {
    if (type && typeof type === 'object' && type.item) {
      if (type.item.name) {
        setTitle(type.item.name);
        if (type.item.info) {
          setDescription(type.item.info);
          if (type.type === 'edit_hashtag') {
            const hashtags = type.item.info.match(/#\w+/g);
            const count = hashtags ? hashtags.length : 0;
            setCount(count);
          }
        }
      }
    }
  }, [type])



  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue);

    const hashtags = inputValue.match(/#\w+/g);
    const count = hashtags ? hashtags.length : 0;
    setCount(count);
  };

  const handleSubmit = async () => {
    if (!idToken) return;
    const data = {};
    data.name = title;
    data.info = description; //has to be inserted as array
    data.type = type;
    data.workspaceId = activeWorkspace.id;

    try {
      if (type.type === 'edit_hashtag' || type.type === 'edit_caption') {
        await axios.post(`${BACKEND_API_URL}/api/auth/user/templates/hashtag/update`, data, {
          headers: { Authorization: `Bearer ${idToken}` }
        });
        closeModal();
      } else {
        await axios.post(`${BACKEND_API_URL}/api/auth/user/templates/hashtag`, data, {
          headers: { Authorization: `Bearer ${idToken}` }
        });
        closeModal();
      }

    } catch (error) {
      console.log(error);
    }

  };
  return (
    <div className="--theme-colors px-4 py-5 sm:p-6 flex-column w-[500px] max-w-full">
      <h2 className="text-2xl font-semibold mb-4 text-center">
        {type === 'hashtag' ? 'Hashtag Groups' : 'Caption Templates'}
      </h2>
      <form className="mb-4">
        <div className="mb-4">
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            placeholder={type === 'hashtag' ? 'Name the hashtag group' : 'Name your caption template'}
            value={title}
            onChange={handleTitleChange}
            required
          />
        </div>

        <div className="mb-4">
          <textarea
            className="w-full h-24 px-3 py-2 border border-gray-300 rounded-md resize-none focus:outline-none focus:border-indigo-500"
            placeholder={type === 'hashtag' ? 'Enter your desired hashtags, as you would like them to apear in the caption. Without commas.' : 'Format your caption template to your desire...'}
            value={description}
            onChange={handleDescriptionChange}
            required
          ></textarea>
        </div>

        <div className={`${type === 'hashtag' || type.type === 'edit_hashtag' ? 'flex' : 'text-right'}`}>
          {type === 'hashtag' || type.type === 'edit_hashtag' ? (
            <div className='flex-1 text-[15px] self-end'>
              Hashtag count: <span className='font-semibold'>{count}</span>
            </div>
          ) : ''}
          <button
            type="button"
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 mr-3"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 mr-2"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

const InstagramConfirmationModal = ({ onConfirm, onCancel }) => {
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="bg-white rounded-lg overflow-hidden w-[500px]">
      <h2 className="text-xl font-semibold text-gray-900 mb-4 text-center mt-4">
        Connect Instagram
      </h2>

      <div className="mb-4">
        <p className="text-gray-600 text-center text-sm px-6">
          Connect an Instagram Creator or Business profile to post and schedule posts on Instagram.
        </p>
      </div>

      {/* Requirements */}
      <div className="space-y-4 px-6">
        {/* Requirement 1: Business or Creator Account */}
        <div className="border border-gray-200 rounded-lg">
          <div 
            className="flex justify-between items-center p-4 cursor-pointer"
            onClick={() => toggleSection('business')}
          >
            <div className="flex items-center">
              <span className="mr-2 text-gray-500">⚠️</span>
              <h3 className="text-base font-medium text-gray-700">
                Must be a Business or Creator profile
              </h3>
            </div>
            <span className="text-gray-400">
              {expandedSection === 'business' ? "▲" : "▼"}
            </span>
          </div>
          
          {expandedSection === 'business' && (
            <div className="px-4 pb-4">
              <p className="text-gray-600 text-sm mb-3">
                Only Instagram Business or Creator profiles are supported. Personal profiles are not supported. Switching to a Business or Creator profile is easy and only takes a few minutes.
              </p>
              <div className="flex gap-2 justify-center mb-2">
                <img
                  src={`${process.env.PUBLIC_URL}/socials/instagram-login-guide/1.png`}
                  alt="Guide to Business or Creator Account"
                  className="w-full max-w-[150px] rounded-md border"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/socials/instagram-login-guide/2.png`}
                  alt="Guide to Facebook Page Connection"
                  className="w-full max-w-[150px] rounded-md border"
                />
              </div>
              <div className="text-sm">
                <a href="https://help.instagram.com/502981923235522" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline block">
                  How to set up a business account
                </a>
                <a href="https://help.instagram.com/2358103564437429" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline block">
                  How to set up a creator account
                </a>
              </div>
            </div>
          )}
        </div>

        {/* Requirement 2: Connected Facebook Page */}
        <div className="border border-gray-200 rounded-lg">
          <div 
            className="flex justify-between items-center p-4 cursor-pointer"
            onClick={() => toggleSection('facebook')}
          >
            <div className="flex items-center">
              <span className="mr-2 text-gray-500">⚠️</span>
              <h3 className="text-base font-medium text-gray-700">
                Must be connected to a Facebook Page
              </h3>
            </div>
            <span className="text-gray-400">
              {expandedSection === 'facebook' ? "▲" : "▼"}
            </span>
          </div>
          
          {expandedSection === 'facebook' && (
            <div className="px-4 pb-4">
              <p className="text-gray-600 text-sm mb-3">
                Make sure you have connected your profile to a Facebook Page, even if it's not in use.
              </p>
              <div className="flex gap-2 justify-center mb-2">
                <img
                  src={`${process.env.PUBLIC_URL}/socials/instagram-login-guide/3.png`}
                  alt="Guide to Business or Creator Account"
                  className="w-full max-w-[200px] rounded-md border"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/socials/instagram-login-guide/4.png`}
                  alt="Guide to Facebook Page Connection"
                  className="w-full max-w-[150px] rounded-md border"
                />
              </div>
              <div className="text-sm">
                <a href="https://www.facebook.com/help/instagram/356902681064399" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline block">
                  How to connect Instagram to a Facebook Page
                </a>
              </div>
            </div>
          )}
        </div>
        
        {/* Requirement 3: Review your Instagram connection */}
        <div className="border border-gray-200 rounded-lg">
          <div 
            className="flex justify-between items-center p-4 cursor-pointer"
            onClick={() => toggleSection('review')}
          >
            <div className="flex items-center">
              <span className="mr-2 text-gray-500">⚠️</span>
              <h3 className="text-base font-medium text-gray-700">
                Review your Instagram connection
              </h3>
            </div>
            <span className="text-gray-400">
              {expandedSection === 'review' ? "▲" : "▼"}
            </span>
          </div>
          
          {expandedSection === 'review' && (
            <div className="px-4 pb-4">
              <p className="text-gray-600 text-sm mb-3">
                Access your Facebook account and <a href="https://www.facebook.com/pages/?category=your_pages" target="_blank" rel="noopener noreferrer" className="text-blue-600 font-medium hover:underline">navigate to the page</a> associated with your Instagram. Go to your <a href="https://www.facebook.com/settings?tab=linked_accounts" target="_blank" rel="noopener noreferrer" className="text-blue-600 font-medium hover:underline">Linked Accounts section</a> and check if there's a "Review Connection" button (shown in blue). If present, click it and follow the prompts to complete the verification process.
              </p>
            </div>
          )}
        </div>
        
        {/* Requirement 4: Check your business integration settings */}
        <div className="border border-gray-200 rounded-lg">
          <div 
            className="flex justify-between items-center p-4 cursor-pointer"
            onClick={() => toggleSection('integration')}
          >
            <div className="flex items-center">
              <span className="mr-2 text-gray-500">⚠️</span>
              <h3 className="text-base font-medium text-gray-700">
                Check your business integration settings
              </h3>
            </div>
            <span className="text-gray-400">
              {expandedSection === 'integration' ? "▲" : "▼"}
            </span>
          </div>
          
          {expandedSection === 'integration' && (
            <div className="px-4 pb-4">
              <p className="text-gray-600 text-sm mb-3">
                Sign in to Facebook using your personal account (not your business page). Open your <a href="https://www.facebook.com/settings?tab=business_tools" target="_blank" rel="noopener noreferrer" className="text-blue-600 font-medium hover:underline">business integration settings</a> and locate the app permissions section. Find the entry for Pally, click "View and Edit", and verify that all necessary permissions are enabled for proper functioning.
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between gap-4 p-4 mt-4">
        <div className="text-sm">
          Having trouble connecting? See our guide or create a ticket at <a href="https://app.frenzypost.com/dashboard/support" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Support</a>
        </div>
      </div>

      {/* Buttons */}
      <div className="flex justify-between gap-4 bg-white py-4 px-6">
        <Button
          variant="destructive"
          onClick={onCancel}
          size="lg"
          className="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
        >
          Cancel
        </Button>
        <Button
          variant="default"
          size="lg"
          onClick={onConfirm}
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
        >
          Connect
        </Button>
      </div>
    </div>
  );
};

const ConnectSocialsModal = ({ closeModal, idToken }) => {
  const [showInstagramConfirmation, setShowInstagramConfirmation] = useState(false);

  const socials = [
    { platform: 'tiktok', info: 'TikTok', component: TikTokLoginButton },
    { platform: 'youtube', info: 'YouTube', component: YoutubeLoginButton },
    { platform: 'instagram', info: 'Instagram', component: InstagramLoginButton },
    { platform: 'facebook', info: 'facebook', component: FacebookLoginButton },
    { platform: 'x', info: 'X', component: XLoginButton },
    { platform: 'threads', info: 'Threads', component: ThreadsLoginButton },
    // Add more social media platforms as needed
  ];

  const handleSocialClick = (platform) => {
    if (platform === 'instagram') {
      setShowInstagramConfirmation(true);
    } else {
      closeModal();
    }
  };

  const handleConfirmInstagram = () => {
    setShowInstagramConfirmation(false);
    authenticateInstagram(idToken);
    closeModal();
  };

  return (
    <>
      {showInstagramConfirmation ? (
        <InstagramConfirmationModal
          onConfirm={handleConfirmInstagram}
          onCancel={() => setShowInstagramConfirmation(false)}
        />
      ) : (
        <div className="px-4 py-5 sm:p-6 modal-wrapper items-center justify-center flex-column text-center w-[500px] max-w-full">
          <h2 className="text-2xl font-semibold text-gray-900">
            Connect to socials
          </h2>
          <p className='text-sm mb-5'>Add your social media you wish to post to.</p>
          {socials.map((social, index) => (
            <div key={index} className='mb-3' onClick={() => handleSocialClick(social.platform)}>
              {social.component && <social.component onClick={() => handleSocialClick(social.platform)} />}
            </div>
          ))}
        </div>
      )}
    </>
  );
};


const VideoUploaderModal = ({ draft, idToken }) => {

  return (
    <div className='min-w-[1200px] max-w-[100%] p-5'>
      <Upload isModal={true} draftSchedule={draft.draft} />
    </div>
  );
};



const ScheduledModal = ({ date, closeModal, idToken }) => {
  const [startDate, setStartDate] = useState(date || new Date());

  useEffect(() => {
    const today = new Date();
    console.log(date);

    if (date < today) {
      setStartDate(today);
    }
  }, [date]);

  return (
    <div className="text-center w-[500px]">
      <div className='flex flex-col'>
        <h2 className="text-2xl font-semibold !text-gray-200 py-2 mb-4 text-center --dark-theme-color">
          Schedule Video
        </h2>
        <p className='w-3/4 mx-auto mb-5'>Select time and date...</p>
      </div>

      <div className='flex flex-col w-max mx-auto'>
        <div>
          <DatePicker
            selected={startDate}
            placeholderText="Schedule"
            onChange={(date) => setStartDate(date)}
            minDate={new Date()} // min now
            maxDate={addMonths(new Date(), 3)} // 3 months max
            shouldCloseOnSelect={false}
            showTimeInput
            timeInputLabel="Time:"
            showIcon={true}
            inline={true}
            className='w-full'
          />
          <div className='text-sm text-gray-500'>
            {startDate?.toLocaleString(undefined, { hour12: false, hour: 'numeric', minute: 'numeric', month: 'long', day: 'numeric' })}
          </div>
        </div>

        <div className='flex w-full  gap-2 my-5 text-white text-sm justify-between'>
          <div className={`bg-blue-500 px-3 py-2 rounded-md cursor-pointer ${startDate === null && '!bg-gray-500 !cursor-not-allowed'}`} onClick={startDate !== null ? (e) => closeModal(startDate) : null}>
            Publish
          </div>
          <div className='bg-red-500 px-3 py-2 rounded-md cursor-pointer' onClick={(e) => closeModal('cancel')}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  )
}

const VerifyDelete = ({ closeModal, drafts, idToken }) => {
  // Flatten all posts from all draft objects
  const allPosts = drafts.flatMap(draft => draft.posts || []);
  const [isCheckedArray, setIsCheckedArray] = useState(new Array(allPosts.length).fill(true));
  const [activeWorkspace] = useLocalStorage("activeWorkspace");
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCheckboxChange = (index) => {
    const updatedCheckedArray = [...isCheckedArray];
    updatedCheckedArray[index] = !updatedCheckedArray[index];
    setIsCheckedArray(updatedCheckedArray);
  };

  const handleOneDelete = async (id) => {
    if (!idToken) return;
    try {
      await axios.delete(BACKEND_API_URL + `/api/auth/user/drafts/${id}`, {
        params: { workspaceId: activeWorkspace.id },
        headers: { Authorization: `Bearer ${idToken}` }
      });
      return;
    } catch (error) {
      console.error('Error deleting draft:', error);
    }
  };

  const confirmDelete = async () => {
    setIsDeleting(true);
    try {
      const checkedItemIds = [];
      isCheckedArray.forEach((isChecked, index) => {
        if (isChecked) {
          checkedItemIds.push(allPosts[index]._id);
        }
      });

      if (checkedItemIds.length > 0) {
        for (const id of checkedItemIds) {
          await handleOneDelete(id);
        }
        closeModal();
      }
    } catch (error) {
      console.error('Error deleting drafts:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="modal-wrapper p-6 rounded-lg text-center shadow-lg flex flex-col w-[500px] max-h-[700px] overflow-y-auto">
      <div className='text-lg font-semibold mb-5'>
        Confirm to delete your posts
      </div>
      {allPosts.map((post, index) => (
        <div key={post._id} className={`flex shadow-sm gap-2 mb-5 justify-between items-center border border-gray-300 ${post.status === 'failed' && 'text-red-400'}`}>
          <input
            type="checkbox"
            className='ml-2 big-checkbox focus:outline-none focus:ring-opacity-0 focus:ring-0'
            checked={isCheckedArray[index]}
            onChange={() => handleCheckboxChange(index)}
          />

          <div className={`px-2 text-gray-700 uppercase `}>
            <div className="max-w-max text-center">
              {post.status} <br />
              <p className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">{post.uploadTo}</p>
              {post.status === 'scheduled' && (
                <p className="text-[10px]">{new Date(post.schedule).toLocaleString()}</p>
              )}
            </div>
          </div>
          <div className="text-gray-900 w-3/5 flex flex-col">
            <div className='truncate font-medium'>{post.title}</div>
            <div className='multi-line-ellipsis text-[10px]'>{post.description}</div>
          </div>
          <div className="text-gray-700 p-1 w-[80px] h-[80px]">
            <img
              src={post.thumbnail || post.firebase_path || `${process.env.PUBLIC_URL}/no-thumbnail.png`}
              alt={post.title}
              className="w-full h-full object-cover shadow-sm"
            />
          </div>
        </div>
      ))}

      <div className="flex justify-center gap-5">
        <Button
          variant="default"
          size="lg"
          onClick={(e) => confirmDelete()}
          disabled={!isCheckedArray.some((isChecked) => isChecked) || isDeleting || allPosts.length === 0}
          className={`group ${(!isCheckedArray.some((isChecked) => isChecked) || isDeleting) && 'bg-gray-500 hover:bg-gray-500'}`}
          title={!isCheckedArray.some((isChecked) => isChecked) ? "Select at least one to delete, or cancel." : ""}
        >
          {isDeleting ? 'Deleting...' : 'Confirm'}
        </Button>

        <Button
          variant="destructive"
          size="lg"
          onClick={closeModal}
          className="bg-red-400 hover:bg-red-500 text-white rounded-md font-semibold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

const ConfirmHideGuide = ({ closeModal, idToken }) => {
  const [activeWorkspace] = useLocalStorage("activeWorkspace");

  const confirmDelete = async () => {
    if (!idToken) return;
    try {
      await axios.post(BACKEND_API_URL + '/api/auth/user/settings', { showGuide: false, workspaceId: activeWorkspace.id }, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
    } catch (error) {
      console.log(error);
    }
    closeModal(true);
  };

  const cancelDelete = () => {
    closeModal(false);
  };

  return (
    <div className="modal-wrapper p-6 rounded-lg text-center shadow-lg flex flex-col">
      <div className='text-lg font-semibold mb-5'>
        Are you sure you want to hide this guide?
      </div>

      <div className="flex justify-center">
        <button
          onClick={confirmDelete}
          className={`group bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 mr-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50`}
        >
          Confirm
        </button>

        <button
          onClick={cancelDelete}
          className="bg-red-400 hover:bg-red-500 text-white font-semibold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};


const AddTeamMemberModal = ({ closeModal, workspace, idToken }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: 'user',
  });
  const [errorResponse, setErrorResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    if (!idToken) return;
    e.preventDefault();
    setIsLoading(true); // Set loading to true when action starts
    try {
      // Send form data to the API as JSON in the request body
      const response = await axios.post(BACKEND_API_URL + `/api/auth/user/workspaces/${workspace.id}/add-member`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      });
      if (response.status === 200) {
        closeModal(true);
      }
    } catch (error) {
      if (error.response.data.error) {
        setErrorResponse(error.response.data.error);
      }
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <div className="modal-wrapper min-w-[500px] --theme-colors p-6 rounded-lg text-center shadow-lg flex flex-col">
      <div className='text-lg font-semibold mb-5'>
        Add a Team Member
      </div>

      <form className='flex flex-col text-start' onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Username"
          value={formData.username}
          onChange={handleChange}
          autoComplete='off'
          className="border border-gray-300 px-3 py-2 rounded-md mb-4 text-black"
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          autoComplete='off'
          value={formData.email}
          onChange={handleChange}
          className="border border-gray-300 px-3 py-2 rounded-md mb-4 text-black"
          required
        />
        {errorResponse && (
          <p className='text-center text-red-500 text-sm'>{errorResponse}</p>
        )}
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
          disabled={isLoading} // Disable button when loading
        >
          {isLoading ? <LoadingIndicator showText={false} showLogo={false} size={5} /> : 'Add Team Member'}
        </button>
      </form>
    </div>
  );
};

const DeleteAccountModal = ({ closeModal, userInfo, idToken }) => {
  const navigate = useNavigate();
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [freeInput, setFreeInput] = useState('');

  const reasons = [
    "I don't find it useful",
    "I am switching to a different service",
    "I am experiencing technical issues",
    "Too difficult to use",
    "Other"
  ];

  const handleCheckboxChange = (reason) => {
    setSelectedReasons((prev) =>
      prev.includes(reason)
        ? prev.filter((r) => r !== reason)
        : [...prev, reason]
    );
  };

  const confirmDelete = async () => {
    if (!idToken) return;
    const admin = render(<DeleteAccountAdmin userInfo={userInfo} reason={selectedReasons} input={freeInput} />)
    const user = render(<DeleteAccountUser userInfo={userInfo} reason={selectedReasons} />)

    try {
      await SendEmail('User has deleted his account', admin, 'contact@frenzypost.com');
      await SendEmail('You have deleted your account at FrenzyPost', user);
      await axios.get(BACKEND_API_URL + '/api/auth/user/delete', {
        headers: { Authorization: `Bearer ${idToken}` }
      });

      navigate(0);
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const cancelDelete = () => {
    closeModal();
  };

  const handleFreeInputChange = (event) => {
    setFreeInput(event.target.value);
  };

  return (
    <div className="modal-wrapper p-6 rounded-lg text-center shadow-lg flex flex-col gap-3 w-[500px]">
      <p className='font-bold text-red-500'>
        DELETE ACCOUNT
      </p>

      <div className="mx-auto w-max">
        <p className="font-semibold mb-2">What is your biggest factor for leaving FrenzyPost?</p>
        <div className="checkbox">
          {reasons.map((reason) => (
            <div key={reason} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={reason}
                checked={selectedReasons.includes(reason)}
                onChange={() => handleCheckboxChange(reason)}
                className="mr-2 medium-checkbox"
              />
              <label htmlFor={reason} className="text-sm">{reason}</label>
            </div>
          ))}
        </div>
      </div>

      <div className="mx-auto w-full mt-2">
        <textarea
          value={freeInput}
          onChange={handleFreeInputChange}
          className="border rounded-lg p-2 w-full"
          rows="3"
          placeholder="Please provide any additional comments or reasons for leaving to help us improve."
        />
      </div>

      <p className='text-[10px] italic'>
        * After 30 days, your account at     will be removed entirely from our system, including videos, templates, history uploads, statistics and personal settings.
      </p>

      <div className="flex justify-center gap-5 items-end">
        <div
          onClick={confirmDelete}
          className={`bg-red-400 hover:bg-red-500 text-white font-semibold py-1 px-2 text-sm cursor-pointer`}
        >
          Delete
        </div>

        <div
          onClick={cancelDelete}
          className="border border-gray-400 hover:bg-gray-100 text-black font-semibold py-1 px-2 text-sm cursor-pointer"
        >
          Cancel
        </div>
      </div>
    </div>
  );
};

const ConfirmDeleteWorkspace = ({ closeModal, workspace, idToken }) => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const handleConfirmDelete = async () => {
    if (!idToken) return;
    setIsLoading(true); // Set loading to true when action starts
    try {
      await axios.delete(`${BACKEND_API_URL}/api/auth/user/workspaces/${workspace.id}`, {
        headers: { Authorization: `Bearer ${idToken}` }
      });
      closeModal(true);
    } catch (error) {
      console.error('Error deleting workspace:', error);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <div className="modal-wrapper --theme-colors p-6 rounded-lg text-center shadow-lg flex flex-col w-[500px]">
      <div className='text-lg font-semibold mb-5'>
        Confirm Delete Workspace
      </div>
      <p className='mb-4'>
        Are you sure you want to delete the workspace <strong>{workspace.name}</strong>? This action cannot be undone and will permanently delete all associated data, including socials, uploads, etc.
      </p>
      <p className='mb-4'>
        Please type the workspace name <strong className='text-red-500 underline'>{workspace.name}</strong> to confirm.
      </p>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        className="border border-gray-300 px-3 py-2 rounded-md mb-4 text-black"
        placeholder="Enter workspace name"
      />
      <div className="flex justify-center gap-5">
        <button
          onClick={handleConfirmDelete}
          disabled={inputValue !== workspace.name || isLoading} // Disable button when loading or input is incorrect
          className={`bg-red-400 hover:bg-red-500 text-white font-semibold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50 ${inputValue !== workspace.name ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isLoading ? <LoadingIndicator showText={false} showLogo={false} size={5} /> : 'Delete'}
        </button>
        <button
          onClick={closeModal}
          className="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const CreateWorkspaceModal = ({ closeModal, otherInfo, idToken }) => {
  const { userInfo, workspace } = otherInfo;
  const [workspaceName, setWorkspaceName] = useState(workspace ? workspace.name : '');
  const [workspaceImage, setWorkspaceImage] = useState(null);
  const [existingImage] = useState(workspace ? workspace.image : null);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const handleImageChange = (e) => {
    setWorkspaceImage(e.target.files[0]);
  };
  const handleSaveWorkspace = async () => {
    setIsLoading(true); // Set loading to true when action starts
    const formData = new FormData();
    formData.append('name', workspaceName);
    formData.append('userId', userInfo.uid);
    formData.append('workspaceId', workspace ? workspace.id : null);
    if (workspaceImage) {
      formData.append('image', workspaceImage);
    }

    try {
      if (workspace) {
        // Edit existing workspace
        await axios.patch(`https://us-central1-socialshare-6946b.cloudfunctions.net/editWorkspace/${workspace.id}`, formData, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'multipart/form-data' }
        });
      } else {
        // Create new workspace
        await axios.post(`https://us-central1-socialshare-6946b.cloudfunctions.net/createWorkspace`, formData, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'multipart/form-data' }
        });
      }
      closeModal(true);
    } catch (error) {
      console.error('Error saving workspace:', error);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <div className="modal-wrapper p-6 rounded-lg text-center shadow-lg flex flex-col w-[500px]">
      <div className='text-lg font-semibold mb-5'>
        {workspace ? 'Edit Workspace' : 'Create New Workspace'}
      </div>
      <input
        type="text"
        placeholder="Workspace Name"
        value={workspaceName}
        onChange={(e) => setWorkspaceName(e.target.value)}
        className="border border-gray-300 px-3 py-2 rounded-md mb-4 text-black"
      />
      {existingImage && !workspaceImage && (
        <img src={existingImage} alt="Workspace" className="w-full h-32 object-cover rounded-md mb-4" />
      )}
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="mb-4"
      />
      <div className="flex justify-center gap-5">
        <button
          onClick={handleSaveWorkspace}
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
          disabled={isLoading} // Disable button when loading
        >
          {isLoading ? <LoadingIndicator showText={false} showLogo={false} size={5} /> : (workspace ? 'Save Changes' : 'Create')}
        </button>
        <button
          onClick={closeModal}
          className="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const LibraryModal = ({ closeModal }) => {
  return (
    <div className="modal-wrapper p-6 rounded-lg text-center shadow-lg flex flex-col w-[700px] max-h-[700px] overflow-y-auto">
      <Library isPage={false} onConfirm={(e) => {
        console.log(e);
        closeModal(e.url);
      }} />
    </div>
  );
};


const ManageUploadModal = ({ closeModal, upload, idToken }) => {
  return (
    <div className="modal-wrapper p-6 rounded-lg text-center shadow-lg flex flex-col w-[700px] max-h-[700px] overflow-y-auto">
      <ManageUpload upload={upload} idToken={idToken} />
    </div>
  );
};


const TodoModal = ({ closeModal }) => {
  return (
    <div className="modal-wrapper p-6 rounded-lg flex flex-col w-[500px]">
      <Todo />
    </div>
  );
};
export default Modal;
